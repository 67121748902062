import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import { AiOutlineDown } from "react-icons/ai";
import Sidebar from "../Sidebar";
import CardDisplay from "react-credit-card-display";

const Cart = (props) => {

  const [Cart, setCart] = useState([]);
  const history = useHistory();
  const [changepayment, setChangepayment] = useState(0);
  const [show, setShow] = useState(false);
  const [see, setSee] = useState(true);
  const [individual, setindividual] = useState("");


  useEffect(() => {
    OldData();
  
  }, []);

  const OldData = async () => {
    const values = await localStorage.getItem("@card_details");
    if (values === null) {
      console.log("Card is empty");
    } else {
      var data = [];
      data.push(values);
      const mydata = JSON.parse(data);
      setCart(mydata);
    }
  };

const deleteCartdetails = (i) => {
    var i = i;
    if (window.confirm('Are you sure you want to Delete?')) {
      const filteredProduct = Cart.filter((cart, index) => {
        return index != i;
      });

      localStorage.setItem("@card_details", JSON.stringify(filteredProduct));

      toast.info("Deleted Successfully");
      setCart(filteredProduct);

    } else {

return false;
    
    }

  };




  


  return (
    <div  id="accountcardlist"      className="wrapper d-flex align-items-stretch">
      <Sidebar Color={6} />
      <div id="content" className="p-4">
        <div style={{marginTop:"-5%"}}>
        {/* id="wrapper" */}
         <div   id="addcartaccount"   onClick={() => {
                history.push("/Payment");
              }} style={{float:"right",paddingRight:"4%" ,color:"#3f51b5" }}><button className="addcartbutton" role="button"><span style={{fontSize:"25px"}}>+</span> ADD</button></div> 

            <ToastContainer />
            <div style={{ marginLeft: "34px", marginTop: "11%", }}>
<div style={{display: Cart.length === 0 ? "block":"none",fontSize:"34px",fontWeight:"700",color:"grey",textAlign:"center"}}> Add your card details</div>
    {Cart.map((item, index) => (
      <div id="listcartpaymentcart" style={{ marginLeft: "12%" , height:"10%", width:"60%" ,  boxShadow: "0 0 50px #d9d9d9", backgroundColor:"#fff",marginTop:"5%",paddingTop:"2%",paddingLeft:"2%"}}>
                    <div  style={{ float:"right",paddingRight:"3%",}}><button className="cartdelete" onClick={() => { deleteCartdetails(index) }} role="button">Delete</button></div>

               <div style={{ display: "flex", }}>
                        
                     
                         <div style={{ display:"flex",paddingRight:"3%"}}>
        <CardDisplay number={item.number} expand={true} square={true} />
      </div>
                    
                        <div>
                          <ul>
                            <li>
                              <label>
                                XXX XXX XXX
                                {item.number
                                  ? item.number.substr(item.number.length - 3)
                                  : ""}

                               </label>
                        
                            </li>
                            <li>
                              {item.Name}
                            </li>
                          </ul>

                        </div>
                      </div>
                     

                    </div>



                  ))}
                </div>
                <br />


        </div>
      </div>
    </div>
  );
};

export default Cart;
