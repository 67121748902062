import React, { useState ,useEffect} from "react";
import axios from "axios";
import "../style/restyle.css";
import ReactStars from "react-rating-stars-component";
import { FaStarHalfAlt,FaStar,FaRegStar } from "react-icons/fa";
import { useHistory} from "react-router-dom";

function Review() {

 const [orderid, setorderid] = useState();
 const history =useHistory()
 const [rating, setrating] = useState(history.location.state.detail.rating);
  const [comment, setcomment] = useState(history.location.state.detail.comment);
  const [oncomment, setoncomment] = useState();



useEffect(()=>{
// alert(history.location.state.detail.rating)
  })

  const formsend1 = async () => {

    const senddata={
      order_id: history.location.state.detail.order_id,
     rating: rating,
     comment: comment,

     } 
    
    
 axios
      .post(
        "https://api.order2go.us:3001/v1/review/create",
        {
         order_id: history.location.state.detail.order_id,
        rating: rating,
        comment: comment,

        },
        {
          headers: {
            ContentType: "application/json",

            Accept: "application/json",

           Authorization:
            "Bearer " +(await localStorage.getItem("@access_token")),

          },
        }
      )
      .then((response) => {
        var stringified = JSON.stringify(response);
        var parsedObj = JSON.parse(stringified);
        
        if (parsedObj.status == true) {
          alert(" Review send  succesfully");
        } else {
          alert(" Review add Success");
        }
        window.history.back()
      })
      .catch((error) => {
        alert(error.message);
        
      });
  }; 



  return (
    <div>
      <main className="bg_gray pattern ">
        <div className="container margin_60_40">
          <div className="row justify-content-center ">
            <div className="col-lg-8 card">
              <div className="box_general write_review ">
                <h1 className="add_bottom_15">Write a review for<span style={{ color: "rgb(236, 52, 89)" }}>Restaurant</span></h1>
                <label className="d-block add_bottom_15">Overall rating</label>
                <div className="row">
                  <div className="col-md-3 add_bottom_25">
                    <div className="add_bottom_15">  <strong className="food_quality_val"></strong>
                      <ReactStars size={16}

                        onChange={newRating => {
                          setrating(newRating);
                        }}
                        value={rating}
                        emptyIcon={<FaRegStar />}
                        
                        halfIcon={<FaStarHalfAlt />}

                        filledIcon={<FaStar />}
                      />
                    </div>
                    <input type="range" min="0" max="10" step="1"  value='4' data-orientation="horizontal" id="food_quality" name="food_quality" style={{ position: "absolute", width: "1px", height: "1px", overflow: "hidden", opacity: "0", }} /><div className="rangeslider rangeslider--horizontal" id="js-rangeslider-0"><div className="rangeslider__fill" style={{ width: "10px", }}></div>
                    <div className="rangeslider__handle" style={{ left: "0px", }}></div>
                    </div>
                  </div>
            
                </div>
 <div className="form-group">
                  <label>Your review</label>
             
          
                  <input className="form-control" onChange={(e)=>setcomment(e.target.value)} value={comment}  type="text" placeholder="If you could say it in one sentence, what would you say?" />

                </div>
            
              
                <button style={{ marginLeft: "50px" }} className="btn_1" onClick={() =>formsend1()}> <span style={{Color:"#fff"}}>Submit review</span></button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
export default Review;
