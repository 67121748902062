import React, { useEffect, useState } from "react";
import {
  MdFavorite,
  MdOutlineStarPurple500,
  MdBookmark,
  MdRateReview,
  MdLocalOffer,
  MdFastfood,
  MdPhone,
  MdRestaurantMenu,
  MdOutlineMenuBook,
  MdWatchLater,
} from "react-icons/md";
import { TbDiscount2 } from "react-icons/tb";

import { GoInfo } from "react-icons/go";
import { FaMapMarkedAlt } from "react-icons/fa";
import { AiOutlineFieldTime } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import "./res.css";

const Header = (props) => {
  const [restaurant, setRestaurant] = useState([]);
  const [wish, setWish] = useState([]);
  const fulldate = new Date();
  let currentday = fulldate.getDay();

  useEffect(() => {
    setWish(props.Resturendheader.wish);
    setRestaurant(props.Resturent);
  });

  return (
    <div>
      <div className="_1637z">
        <div className="_8MlDE">
          <div className="_3TBoD" style={{ display: "flex" }}>
            <div className="_3mJdF">
              <div className="H5I6J">
                <img
                  className="_2tuBw _12_oN _3sCWI"
                  style={{
                    width: "100%",
                    height: "13vw",
                  }}
                  alt=""
                  src={`https:api.order2go.us:3001/uploads//images//${
                    !props.Resturendheader.isFav
                      ? restaurant.picture == null
                        ? "Image-1636372408245.jpg"
                        : restaurant.picture.split("\\")[2]
                      : props.Resturendheader.restaurantPicture == null
                      ? "Image-1636372408245.jpg"
                      : props.Resturendheader.restaurantPicture.split("\\")[2]
                  }`}
                />
              </div>
            </div>
            <div className="_2Fixt">
              <div className="_1WDSQ">
                <div className="U-jcg">
                  <div className="OEfxz">
                    <h1
                      title="The Vellore Kitchen"
                      className="_3aqeL"
                      style={{ color: "white" }}
                    >
                      {!props.Resturendheader.isFav
                        ? restaurant.name
                          ? restaurant.name.split(" ")[0]
                          : restaurant.name
                        : props.Resturendheader.restaurantName}
                    </h1>
                  </div>
                </div>
                <div className="_2cMZ_">
                  <span className="_20F32">
                    <span>
                      <span className="icon-star _2n5YQ"></span>4.2
                    </span>
                  </span>
                  <span className="_20F32">
                    <span className="_27qo_">33 MINS</span>
                  </span>
                  <span className="_20F32">
                    <span>₹350</span> for two
                  </span>
                </div>
                <div className="_1BpLF">
                  <div className="Gf2NS _2Y6HW _2x0-U">
                    {!props.Resturendheader.isFav
                      ? restaurant.address1
                      : props.Resturendheader.address1}
                  </div>
                  <div className="_3Plw0 JMACF">
                    {!props.Resturendheader.isFav
                      ? restaurant.address3 + " " + restaurant.address2
                      : props.Resturendheader.address2}
                  </div>
                  <div className="_2aZit _2fC4N" style={{ marginTop: "5%" }}>
                    <div className="_2iUp9 ">
                      <div className="_2l3H5">
                        <span className="timedes" style={{ fontSize: "1.2vw" }}>
                          <MdOutlineStarPurple500 style={{ color: "orange" }} />
                          {props.Resturendheader.Rating}
                        </span>
                      </div>
                    </div>
                    <div className="_2iUp9 ">
                      <div className="_2l3H5">
                        <span
                          className="_27qo_"
                          style={{
                            fontSize: "1.2vw",
                            textTransform: "capitalize",
                          }}
                        >
                          12km
                        </span>
                      </div>
                    </div>
                    <div className="_2iUp9 ">
                      <div className="_2l3H5" style={{ fontSize: "1.2vw" }}>
                        <MdPhone />{" "}
                        {!props.Resturendheader.isFav
                          ? restaurant.phone
                          : props.Resturendheader.phone}
                      </div>
                      <div className="_1De48"> </div>
                    </div>
                    <div className="_2iUp9 ">
                      <div className="_2l3H5">
                        <a
                          className="timedes"
                          style={{ fontSize: "1.2vw", color: "white" }}
                        >
                          <MdWatchLater
                            className="timedes"
                            style={{ fontSize: "1.3vw" }}
                          />
                          {props.Resturendheader.StartTime}-
                          {props.Resturendheader.EndTime}
                        </a>
                      </div>
                      <div className="_1De48"> </div>
                    </div>
                    <div className="_2iUp9 ">
                      <div className="_2l3H5">
                        <a
                          className="getdesc"
                          href={`https://www.google.com/maps/@${restaurant.lat},${restaurant.long},19z`}
                          style={{ fontSize: "1.2vw", color: "white" }}
                        >
                          <FaMapMarkedAlt />
                          Get Direction
                        </a>
                      </div>
                      <div className="_1De48"> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="Z4sK8">
              <div className="_2FyFZ icJ_O">
                <div className="QWCzK">Offer</div>
                <div className="_3F2Nk">
                  <div className="DM5zR">
                    <TbDiscount2
                      className="getdesc"
                      style={{ fontSize: "2.6vw", fontWeight: "200" }}
                    />
                    <div className="_3lvLZ" style={{ fontSize: "1.2vw" }}>
                      {props.Resturendheader.discountPercent === null
                        ? 0
                        : props.Resturendheader.discountPercent}
                      % offer On {props.Resturendheader.current1}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="_2e0fx">
          <div className="LrkrN">
            <div className="_1g_1a" style={{ width: "100%" }}>
              <div className="totalbt" style={{ display: "flex" }}>
                <div
                  id="homebutton"
                  // onClick={() => props.Resturendheader.Changeinfo()}
                >
                  <div
                    className="_2Epw9"
                    style={{
                      paddingLeft: "20px",
                      marginLeft: "2px",
                    }}
                  >
                    <span className="_2FDKw">
                      <span
                        className="_1JjHr"
                        style={{
                          fontSize: "1.2vw",
                        }}
                      >
                        <MdRestaurantMenu style={{ fontSize: "1.3vw" }} /> Info
                      </span>
                    </span>
                  </div>
                </div>

                <div
                  onClick={() => props.Resturendheader.changereview()}
                  className="_2Epw9"
                  style={{
                    paddingLeft: "20px",
                    marginLeft: "2px",
                    cursor: "pointer",
                  }}
                >
                  <span className="_2FDKw">
                    {props.Resturendheader.cateReview === "block" ? (
                      <span
                        className="_1JjHr"
                        onClick={() => props.Resturendheader.changereview()}
                        style={{
                          cursor: "pointer",
                          fontSize: "1.2vw",
                        }}
                      >
                        <MdRateReview /> Review
                      </span>
                    ) : (
                      <span
                        className="_1JjHr"
                        onClick={() => props.Resturendheader.changereview()}
                        style={{
                          cursor: "pointer",
                          fontSize: "1.2vw",
                        }}
                      >
                        <MdFastfood /> Category
                      </span>
                    )}
                  </span>
                </div>
                <div
                  className="_2Epw9"
                  onClick={() => props.Resturendheader.Changebook()}
                  style={{
                    paddingLeft: "20px",
                    marginLeft: "2px",
                    cursor: "pointer",
                  }}
                >
                  <span
                    className="_2FDKw"
                    onClick={() => props.Resturendheader.Changebook()}
                  >
                    <span
                      className="_1JjHr"
                      style={{
                        cursor: "pointer",
                        fontSize: "1.1vw",
                      }}
                    >
                      <MdOutlineMenuBook style={{ fontSize: "1.3vw" }} />{" "}
                      BookTable
                    </span>
                  </span>
                </div>
                <div
                  className="_2Epw9 "
                  style={{
                    paddingLeft: "20px",
                    marginLeft: "2px",
                    cursor: "pointer",
                  }}
                >
                  <span
                    className="_2FDKw"
                    onClick={() => props.Resturendheader.Fav(restaurant.id)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <span
                      className="_1JjHr"
                      style={{
                        cursor: "pointer",
                        fontSize: "1.2vw",
                        color: wish === 2 ? "#ed2359" : "",
                      }}
                    >
                      <MdFavorite style={{ fontSize: "1.3vw" }} /> Favourite
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
