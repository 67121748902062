import React, { useState, useEffect } from "react";
import Sidebar from '../Sidebar';
import axios from 'axios';
import Card from "react-bootstrap/Card";
import { useHistory } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";

var userdata =
  JSON.parse(localStorage.getItem("@user_data")) == null
    ? []
    : JSON.parse(localStorage.getItem("@user_data"));



const Sidebarreview = (props) => {

  const [userreview, setUserreview] = useState([]);
  const [deletereview, setdeletereview] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getfavdata();
  }, []);

  
  useEffect(() => {
    deleteReview ();
  }, []);
  
  const getfavdata = async () => {
    axios
      .get("https://api.order2go.us:3001/v1/review/list", {
        headers: {
          "Content-Type": "application/json",
          from_mobile: "true",
           restaurant_id: 21,
           Authorization:
            "Bearer " + (await localStorage.getItem("@access_token")),
        },
      })
      .then((response) => {
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);
        if (parsedObj.status == true) {
          setUserreview(parsedObj.data);
          console.log(parsedObj.data, "Order Object");
        } else {

        }
      });
  };


const deleteReview = async (orderid, ratings, comments) => {
      axios
      .post(
        "https://api.order2go.us:3001/v1/review/delete",
        {
          order_id: orderid,
          rating: ratings,
          comment: comments,
        },
        {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer " + (await localStorage.getItem("@access_token")),
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);
         setdeletereview(parsedObj.data);
         if (response.data.status == true) {
          window.location.reload(false);
          console.log("Review Delete Sucess");
        } else if (response.data.status === false) {
          console.log("Review Delete failure");        
        }
      })
      .catch((error) => {
      });
  };

  
const FormEdit =(e)=>{
  props.history.push({ 
    pathname: '/review',
    state:{ detail:e },
});
}



return (
<>
      
<div  className="wrapper d-flex align-items-stretch">
<Sidebar Color={7} />
  
{userreview.length>0?(
    <div style={{marginTop:"8%" ,}} id="content" className="p-4">
    {userreview.map((item, index) => (  
     <div style={{marginTop:"-10%", display:"flex"}} >

     <div id="pane-B" role="tabpanel" aria-labelledby="tab-B">
   <div
         id="collapse-B"
         className="collapse show"
         role="tabpanel"
         aria-labelledby="heading-B"
       >
   
         <div   className="card-body reviews">
  
           <div     className="row add_bottom_45 d-flex align-items-center">
             <div className="col-md-3">
         </div>
            
           </div>
           <div   style={{width:"800px",}} id="reviews">
             <div   id="sidebarreviewlist" style={{height:"150px"}} className="review_card">
               <div className="row">
                 
                 <div className="col-md-2 user_info">
                 </div>
                 <div className="col-md-10 review_content">
                   <div className="clearfix add_bottom_15">
                     <span className="rating">  <AiFillStar
                       style={{
                         color: "orange",
                         fontSize: "23px",
                         marginBottom:"10px"
                        
                       }}
                     />
                       {item.rating}<small style={{fontSize:"24px"}}>/5</small> <strong>Rating average</strong>
                     </span>
                     {/* <em>Published 54 minutes ago</em> */}
                   </div>
                   {/* <h4>"Great Location</h4> */}
                   <p>
               {item.comment}
                   </p>
                   <div style={{display:"flex",justifyContent:"space-evenely",float:"right",marginTop:"-6%"}}>
<button style={{marginRight:"10%"}} className="reviewlist-7" role="button" onClick={()=>FormEdit(item)}>Edit</button>
<button className="reviewlist-7" role="button" onClick={()=>deleteReview(item.order_id,item.rating,item.comment)}>Delete</button>
</div>
</div>

               </div>
             </div>
           </div>
         </div>
      
       </div>
     </div>
     </div>
     ))}
 </div>
):(
<h4 style={{margin:"28%"}}>
            No Data Found
          </h4>
)}



    </div>
     
    
    </>

  )
}

export default Sidebarreview;