import React, { useState, useEffect } from "react";
import { AiFillStar } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import Sidebar from "../Sidebar";
import { MdFavoriteBorder, MdFavorite } from "react-icons/md";
import ReactStars from "react-rating-stars-component";
import { FaStarHalfAlt, FaStar, FaRegStar } from "react-icons/fa";
import axios from "axios";
import "./style/favfoodlist.css";

var userdata =
  JSON.parse(localStorage.getItem("@user_data")) == null
    ? []
    : JSON.parse(localStorage.getItem("@user_data"));

const Favrestaurant = (props) => {
  const [favrestaurant, setfavrestaurant] = useState([]);
  const [empty, setempty] = useState(false);
  const [notempty, setNotempty] = useState(false);

  const history = useHistory(0);
  useEffect(() => {
    getfavdata();
  }, []);

  const getfavdata = async () => {
    axios
      .get("https://api.order2go.us:3001/v1/fav_restaurant/list", {
        headers: {
          "Content-Type": "application/json",
          from_mobile: "true",
          restaurant_id: 21,
          Authorization:
            "Bearer " + (await localStorage.getItem("@access_token")),
        },
      })
      .then((response) => {
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);
        if (parsedObj.status == true) {
          setfavrestaurant(parsedObj.data);
          console.log(parsedObj.data, "Order Object");
        } else {
          console.log("Failed To Get    favourite Restaurant data");
        }
      });
  };

  const handleClick1 = async (props) => {
    axios
      .post(
        "https://api.order2go.us:3001/v1/fav_restaurant/delete",
        {
          restaurant_id: props.restaurant_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer " + (await localStorage.getItem("@access_token")),
          },
        }
      )
      .then((response) => {
        var stringified = JSON.stringify(response);
        var parsedObj = JSON.parse(stringified);
        if (parsedObj.status == 200) {
          window.location.reload(false);
        } else {
          alert("Failed To Delete Favourites");
        }
      })

      .catch((error) => {
        alert(error.message);
      });
  };

  const viewRest = (params) => {
    params.id = params.restaurant_id;
    history.push({
      pathname: `/Resturent/${params.restaurant_id}`,
      state: { restaurant: params, isFav: true },
    });
  };

  return (
    <div className="wrapper d-flex align-items-stretch">
      <Sidebar Color={4} />

      <div id="content" className="p-4">
        <div
          style={{ marginTop: "8%", marginLeft: "4%" }}
          className="container"
        >
          <div className="row">
            <div
              style={{
                marginLeft: "25%",
                color: "grey",
                fontSize: "30px",
                display: favrestaurant.length == 0 ? "block" : "none",
              }}
            >
              {" "}
              There No favourite Restaurants{" "}
            </div>

            {favrestaurant.map((item, index) => (
              <div
                style={{ height: "300px", marginLeft: "3%" }}
                className="col-xl-5 col-lg-6 col-md-6 col-sm-6"
              >
                {
                  item.restaurant != undefined ? (
                    <div
                      className="card strip"
                      style={{ width: "96%" }}
                      key={index}
                    >
                      <figure>
                        <button
                          className="cart"
                          onClick={() => handleClick1(item)}
                        >
                          <span
                            className="ribbon off"
                            style={{ backgroundColor: "transparent" }}
                          >
                            {" "}
                            <MdFavorite
                              style={{ fontSize: "18px", color: "crimson " }}
                            />
                          </span>
                        </button>
                        <img
                          src={`https://api.order2go.us:3001/${
                            item.restaurant.picture === null
                              ? "uploads\\images\\Image-1643187014014.jpg"
                              : item.restaurant.picture
                          }`}
                          className="img-fluid lazy loaded"
                          alt=""
                          data-was-processed="true"
                        />
                      </figure>

                      <ul style={{ height: "65px" }}>
                        <li
                          style={{
                            paddingLeft: "10px",
                            position: "relative",
                            bottom: "10px",
                          }}
                        >
                          <h6 style={{ lineHeight: "1.7" }}>
                            <span
                              style={{ fontWeight: "bolder", display: "flex" }}
                            >
                              <div>{item.restaurant.name}</div>
                              {/* .substring(0, 20) */}
                              <div
                                style={{
                                  backgroundImage:
                                    "linear-gradient(to bottom right, #ee6c4d, #ffafcc)",
                                  color: "white",
                                  borderRadius: "7px",
                                  fontSize: "11px",
                                  padding: "2px",
                                  height: "19px",
                                  marginLeft: "5px",
                                  fontWeight: "500",
                                }}
                              >
                                {" "}
                                Indian{" "}
                              </div>
                              <div
                                style={{
                                  backgroundImage:
                                    "linear-gradient(to bottom right, #4c669f,   #0081a7)",
                                  color: "white",
                                  borderRadius: "7px",
                                  fontSize: "11px",
                                  padding: "3px",
                                  height: "19px",
                                  marginLeft: "5px",
                                  fontWeight: "500",
                                }}
                              >
                                {" "}
                                12Km{" "}
                              </div>
                            </span>
                            <span
                              style={{ fontSize: "14px", fontWeight: "400" }}
                            >
                              {item.restaurant.address2 +
                                ", " +
                                item.restaurant.address3}
                            </span>
                          </h6>
                        </li>

                        <li style={{ lineHeight: "1.6" }}>
                          <div className="  score">
                            <span>
                              <em></em>
                            </span>
                            <strong
                              style={{
                                backgroundColor: "white",
                                color: "black",
                                borderRadius: "10px",
                                marginLeft: "-5%",
                                height: "30px",
                                boxShadow: "0 0 50px #d9d9d9",
                                padding: "1px 1px 0px 1px",
                              }}
                            >
                              {" "}
                              <AiFillStar
                                style={{
                                  color: "orange",
                                  fontSize: "18px",
                                  marginTop: "5px",
                                }}
                              />
                              <span
                                style={{
                                  Left: "15px",
                                  fontSize: "15px",
                                  fontWeight: "990",
                                }}
                              >
                                4.5
                              </span>
                            </strong>
                          </div>
                          <div className="score">
                            {" "}
                            <span
                              style={{
                                textAlign: "center",
                                backgroundColor: "#159848",
                                color: "white",
                                borderRadius: "5px",
                                height: "23px",
                                padding: "6px",
                                marginLeft: "5%",
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              <a
                                style={{ color: "white" }}
                                onClick={() => {
                                  viewRest(item);
                                }}
                              >
                                {" "}
                                OPEN{" "}
                              </a>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  ) : null

                  // <h1>hi</h1>

                  // empty(true)
                }
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Favrestaurant;
