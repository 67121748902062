import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./style/favfoodlist.css";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Card from "react-bootstrap/Card";
import { MdFavoriteBorder, MdFavorite } from "react-icons/md";
import Sidebar from "../Sidebar";
import { UserContext } from "../../../UserContext";
import Popup from "reactjs-popup";

var userdata =
  JSON.parse(localStorage.getItem("@user_data")) == null
    ? []
    : JSON.parse(localStorage.getItem("@user_data"));

    const PRODUCTS =
    JSON.parse(localStorage.getItem("@cartItem")) == null
      ? []
      : JSON.parse(localStorage.getItem("@cartItem"));
  const CLONE_PRODUCTS = JSON.parse(JSON.stringify(PRODUCTS));


const Foodlist = (props) => {
  const [products, setProducts] = useState(CLONE_PRODUCTS);
  const { setCartsize } = useContext(UserContext);
  const [ResetCart, setResetCart] = useState();
  const fulldate = new Date();
  const [orders, setOrders] = useState([]);
  const history = useHistory();
  const [cartData, setCartData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  

  useEffect(() => {
  setProducts(CLONE_PRODUCTS)
    getfavdata();
    getDatas()
  }, []);




  const getDatas = async () => {

    var values = await localStorage.getItem("@cartItem");
    if (values === null) {
      setCartData([]);
    } else {
      var data = [];
      data.push(values);
      const mydata = JSON.parse(data);
      setCartData(mydata);
    }

  };


  const getfavdata = async () => {
    axios
      .get("https://api.order2go.us:3001/v1/fav_food/list", {
        headers: {
          "Content-Type": "application/json",
          from_mobile: "true",
          restaurant_id: 21,
          Authorization:
            "Bearer " + (await localStorage.getItem("@access_token")),
        },
      })
      .then((response) => {
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);
        if (parsedObj.status == true) {
          setOrders(parsedObj.data);
        } else {
        }
      });
  };

  const handleClick1 = async (props) => {
    axios
      .post(
        "https://api.order2go.us:3001/v1/fav_food/delete",
        {
          menu_id: props.menu_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer " + (await localStorage.getItem("@access_token")),
          },
        }
      )
      .then((response) => {
        var stringified = JSON.stringify(response);
        var parsedObj = JSON.parse(stringified);

        if(parsedObj.data.status == true){
          alert(parsedObj.data.message)
          window.location.reload();
        }else{
          alert(parsedObj.data.message)
        }
  
      })
      .catch((error) => {
      });
  };

  const CheckAlreadyCart = (props) => {
    setResetCart({
      categoryID: props.menu.category_id,
      description: props.menu.desc,
      discount:props.discount,
      id: props.id,
      menuID: props.menu_id,
      name: props.menu.name,
      picture: props.menu.picture,
      price: props.menu.menu_price.price,
      qty: 1,
      tax: 0,
      restaurantID: props.menu.restaurant_id,
      subCategoryID: props.menu.sub_category_id,
      min_order_amount:0,
    });


    const idToRemove = props.id;
    const idToRemove1 = props.menu.restaurant_id;

    if (cartData.length === 0) {
      storeCartData(props);
    } else {
      // var item = products.find((item) => item.categoryID === props.menu.category_id);
      var item = products.find((item) => item.menuID === props.menu_id);
      var item1 = products.find((item) => item.restaurantID != idToRemove1);
      var i = 0;

      if (item) {
        alert("ITEM ALREADTY ADDED TO CART");
        return
      } else if (item1) {
        toggleModal();
        return;
      } else {
        storeCartData(props);
        toast.success("ITEM ADDED TO CART Successfully");
      }
    }
  };

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const Change = () => {
    return (
      <Popup
        open={isOpen}
        className="popup-modal-container-box"
        modal
        // lockScroll={true}
        closeOnDocumentClick={false}
      >
        <div class="_2835q">
          <div class="tdLYq">
            <div class="zmgWM">
              <div class="R4-yb">Items already in cart</div>
              <div class="VzKYj">
                <div class="_3XWJf">
                  Your cart contains items from other restaurant. Would you like
                  to reset your cart for adding items from this restaurant?
                </div>
              </div>
            </div>
          </div>
          <div class="_3G9po">
            <button class="b0G1m" onClick={toggleModal}>
              No
            </button>
            <span class="U5Ri6"></span>
            <button class="_2-MHS" onClick={() => storeCartData()}>
              Yes, start afresh
            </button>
          </div>
        </div>
      </Popup>
    );
  };




  const storeCartData = async (props) => {
    // alert(props)

    if (props === undefined) {
      setIsOpen(!isOpen);
      localStorage.removeItem("@cartItem");
      setProducts([]);

      var newCartItem = ResetCart;
    } else {
      var newCartItem = 
      {
        categoryID: props.menu.category_id,
        description: props.menu.desc,
        discount:props.discount,
        id: props.id,
        menuID: props.menu_id,
        name: props.menu.name,
        picture: props.menu.picture,
        price: props.menu.menu_price.price,
        qty: 1,
        tax: 0,
        restaurantID: props.menu.restaurant_id,
        subCategoryID: props.menu.sub_category_id,
        min_order_amount:0,
      };
    }

    let existingTransactions = await getCartData();
    if (existingTransactions.length === 0) {
      var newarr = existingTransactions;
    } else {
      var newarr = JSON.parse(existingTransactions);
    }
    const updatedTransactions = [...newarr, newCartItem];
    await localStorage.setItem(
      "@cartItem",
      JSON.stringify(updatedTransactions)
    );
    setProducts(updatedTransactions);
    setCartsize(updatedTransactions.length);
    alert("ITEM ADDED TO CART Successfully")
    window.location.reload();
  };

  const getCartData = () => {
    let transactions = localStorage.getItem("@cartItem");
    if (transactions) {
      return transactions;
    } else {
      return new Array();
    }
  };


  return (
    <div className="wrapper d-flex align-items-stretch">
      <Sidebar Color={5} />
      <ToastContainer />
      {Change()}
      <div id="content" className="p-4">
        <div
          style={{
            marginLeft: "35%",
            color: "grey",
            fontSize: "30px",
            display: orders.length == 0 ? "block" : "none",
          }}
        >
          {" "}
          There No favourite foods{" "}
        </div>
        {orders.map((item, index) => (
          <div
            id="favfoodcard"
            style={{
              position: "relative",
              left: "70px",
              width: "500px",
              textAlign: "center",
              marginLeft: "15%",
              borderRadius: "10px",
              marginTop: "30px",
            }}
            className="containerfood "
          >
            <Card>
              <div className="foodcard">
                <div className="imagefood">
                  <img
                    src={`https://api.order2go.us:3001/${
                      item.menu.picture === null
                        ? "uploads\\images\\Image-1636982417920.jpg"
                        : item.menu.picture
                    }`}
                    className="lazy loaded"
                    alt=""
                    style={{
                      padding: "4px",
                      width: "120px",
                      height: "102px",
                      marginRight: "4px",
                    }}
                  />
                </div>
                <div
                  className="foodname"
                  style={{
                    position: "relative",
                    top: "10px",
                    fontWeight: "500",
                  }}
                >
                  {item.menu.name}
                </div>
                <div
                  className="fooddetails"
                  style={{ position: "relative", top: "10px" }}
                >
                  <p>{item.menu.desc}</p>
                </div>

                <div className="price" style={{ textAlign: "center" }}>
                  $ {item.menu.menu_price.price}
                  <button className="cart" onClick={() => handleClick1(item)}>
                    <MdFavorite
                      style={{ fontSize: "18px", color: "crimson" }}
                    />
                  </button>
                </div>
                <div className="addcart" style={{ textAlign: "center" }}>
                  <a
                    style={{
                      borderRadius: "12px",
                      cursor: "pointer",
                      fontSize: "13px",
                      textDecoration: "none",
                    }}
                    className="button-34"
                    onClick={() => CheckAlreadyCart(item)}
                  >
                    Add Cart
                  </a>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Foodlist;
