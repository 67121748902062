import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import "./style/restyle.css";

import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Cart from "./restaurants/Cart";
import Header from "./restaurants/Header";
import Categoryslide from "./restaurants/Category";
import Popup from "reactjs-popup";
import { UserContext } from "../../UserContext";

function formatDate(date) {
  return [
    ("0" + date.getDate()).slice(-2),
    ("0" + (date.getMonth() + 1)).slice(-2),
    date.getFullYear(),
  ].join("-");
}
function formatCurrency(value) {
  return Number(value).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
}
const Resturent = (props) => {
  const PRODUCTS =
    JSON.parse(localStorage.getItem("@cartItem")) == null
      ? []
      : JSON.parse(localStorage.getItem("@cartItem"));

  const [count, setCount] = useState(1);
  const CLONE_PRODUCTS = JSON.parse(JSON.stringify(PRODUCTS));
  const [products, setProducts] = useState(CLONE_PRODUCTS);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [taxdetail, setTax] = useState(0);
  const [MinOrderAmount, setMinOrderAmount] = useState(0);
  const subTotal = products.reduce((total, product) => {
    return total + product.price * +product.qty;
  }, 0);
  const discount = (subTotal * discountPercent) / 100;
  const tax = (subTotal * taxdetail) / 100;
  const total = subTotal;
  const [peoplecount, setPeoplecount] = useState(1);
  const [Category, setCategoryData] = useState([]);
  const [categorydetail, setCategorydetail] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [calReview, setCalReview] = useState("none");
  const [cateReview, setCateReview] = useState("block");
  const [info, setinfo] = useState("block");
  const [wish, setWish] = useState(1);
  const [date, setDate] = useState(new Date());
  const [current, setCurrent] = useState(formatDate(new Date()));
  const [current1, setCurrent1] = useState(formatDate(new Date()));
  const [Review, setReview] = useState("none");
  const history = useHistory();
  const [categoryname, setCategoryname] = useState();
  const [Restaurantid, setRestaurantid] = useState(props.match.params.id);
  const [user, setuser] = useState("");
  const [status, setstatus] = useState(false);
  const [ResetCart, setResetCart] = useState(false);
  const [restaurant, setRestaurant] = useState(
    history.location.state.restaurant
  );
  const [Rating, setRating] = useState([]);
  const [StartTime, setStartTime] = useState([]);
  const [EndTime, setEndTime] = useState([]);
  const [time, setTime] = useState(false);
  const [time1, setTime1] = useState(false);
  const fulldate = new Date();
  let currentday = fulldate.getDay();
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [restaurantName, setRestaurantName] = useState("");
  const [restaurantPicture, setRestaurantPicture] = useState("");
  const [phone, setPhone] = useState("");
  const [isFav, setIsFav] = useState(history.location.state.isFav);

  const { setCartsize } = useContext(UserContext);

  useEffect(async () => {
    // alert(""+history.location.state.isFav)
    setuser(
      JSON.parse(localStorage.getItem("@user_data")) === null ? false : true
    );
    getDatas();
    setRestaurantid(props.match.params.id);

    setRestaurant(history.location.state.restaurant);

    listAllFavourites();
    window.scrollTo(0, 0);
    async function fetchMyAPI() {
      let response = await fetch(
        `https://api.order2go.us:3001/v1/categories/findAllByRestaurant`,
        {
          headers: {
            "Content-Type": "application/json",
            from_mobile: "true",
            restaurant_id: props.match.params.id,
          },
        }
      );
      response = await response.json();
      var stringified = JSON.stringify(response);
      var parsedObj = JSON.parse(stringified);
      if (parsedObj.status === true) {
        setCategoryData(parsedObj.data);
        Categorylist(parsedObj.data);
      } else {
        toast.info("Empty Restaurant data");
      }
    }
    fetchMyAPI();

    await discountdetails();
    await setCartDiscountDetails();
  }, []);

  const listAllFavourites = async () => {
    axios
      .get("https://api.order2go.us:3001/v1/fav_restaurant/list", {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + (await localStorage.getItem("@access_token")),
        },
      })
      .then((response) => {
        const idToCheck = Restaurantid;

        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);

        if (parsedObj.status == true) {
          response.data.data.map((item, index) => {
            if (item.restaurant_id == idToCheck) {
              setWish(2);
            }
          });
        } else {
          setWish(1);
        }
      })
      .catch((error) => {
        setWish(1);
      });
  };

  const Categorylist1 = (props) => {
    setCategoryname(props.name);
    setstatus(true);
    async function fetchMyAPI() {
      let response = await fetch(
        `https://api.order2go.us:3001/v1/sub_categories/findAllByRestaurant?category_id=${props.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            from_mobile: "true",
            restaurant_id: Restaurantid,
          },
        }
      );
      response = await response.json();
      var stringified = JSON.stringify(response);
      var parsedObj = JSON.parse(stringified);
      if (parsedObj.status === true) {
        setCategorydetail(parsedObj.data);
        subcategory_list1(parsedObj.data);
        subcategory_list(parsedObj.data);
      } else {
        setCategorydetail([]);
      }
    }
    fetchMyAPI();
  };

  const Categorylist12 = (props) => {
    setCategoryname(props.name);
    setstatus(true);
    async function fetchMyAPI() {
      let response = await fetch(
        `https://api.order2go.us:3001/v1/sub_categories/findAllByRestaurant?category_id=${props.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            from_mobile: "true",
            restaurant_id: Restaurantid,
          },
        }
      );
      response = await response.json();
      var stringified = JSON.stringify(response);
      var parsedObj = JSON.parse(stringified);
      if (parsedObj.status === true) {
        setCategorydetail(parsedObj.data);
      } else {
        toast.info("Empty Category data");
      }
    }
    fetchMyAPI();
  };

  const Categorylist = (props) => {
    setCategoryname(props[0].name);

    async function fetchMyAPI() {
      let response = await fetch(
        `https://api.order2go.us:3001/v1/sub_categories/findAllByRestaurant?category_id=${props[0].id}`,
        {
          headers: {
            "Content-Type": "application/json",
            from_mobile: "true",
            restaurant_id: Restaurantid,
          },
        }
      );
      response = await response.json();
      var stringified = JSON.stringify(response);
      var parsedObj = JSON.parse(stringified);

      if (parsedObj.status === true) {
        subcategory_list(parsedObj.data);
      } else {
        toast.error("Empty Category data");
      }
    }
    fetchMyAPI();
  };

  const subcategory_list = (props) => {
    async function fetchMyAPI() {
      let response = await fetch(
        `https://api.order2go.us:3001/v1/menus/FindAllByCategory?category_id=${props[0].category_id}&sub_category_id=${props[0].id}`,
        {
          headers: {
            "Content-Type": "application/json",
            from_mobile: "true",
            restaurant_id: Restaurantid,
          },
        }
      );
      response = await response.json();
      var stringified = JSON.stringify(response);
      var parsedObj = JSON.parse(stringified);

      if (parsedObj.status == true) {
        setSubCategory(parsedObj.data);
      } else {
      }
    }
    fetchMyAPI();
  };

  const subcategory_list1 = (props) => {
    async function fetchMyAPI() {
      let response = await fetch(
        `https://api.order2go.us:3001/v1/menus/FindAllByCategory?category_id=${props.category_id}&sub_category_id=${props.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            from_mobile: "true",
            restaurant_id: Restaurantid,
          },
        }
      );
      response = await response.json();
      var stringified = JSON.stringify(response);
      var parsedObj = JSON.parse(stringified);
      if (parsedObj.status == true) {
        setSubCategory(parsedObj.data);
      } else {
      }
    }

    setstatus(true);
    fetchMyAPI();
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "grey",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "grey" }}
        onClick={onClick}
      />
    );
  }

  let settings = {
    arrows: true,
    dots: true,
    swipe: false,
    infinite: true,
    speed: 500,
    slidesToShow: Category.length > 6 ? 7 : Category.length,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow style={{ marginBottom: "3%" }} />,
    responsive: [
      // {
      //   breakpoint: 1024,
      //   settings: {
      //     slidesToShow: 3,
      //     slidesToScroll: 3,
      //     infinite: true,
      //     dots: true
      //   }
      // },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
    // prevArrow:true,
  };

  const Fav = async (props) => {
    if (localStorage.getItem("@access_token") === null) {
      alert("Please Login Try again");
    } else {
      if (wish === 1) {
        axios
          .post(
            "https://api.order2go.us:3001/v1/fav_restaurant/create",
            {
              restaurant_id: props,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization:
                  "Bearer " + (await localStorage.getItem("@access_token")),
              },
            }
          )
          .then((response) => {
            toast.success(response.data.message);
          })
          .catch((err) => {});

        setWish(2);
      } else if (wish != 1) {
        axios
          .post(
            "https://api.order2go.us:3001/v1/fav_restaurant/delete",
            {
              restaurant_id: props,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization:
                  "Bearer " + (await localStorage.getItem("@access_token")),
              },
            }
          )

          .then((response) => {
            var stringified = JSON.stringify(response);
            var parsedObj = JSON.parse(stringified);
            if (parsedObj.status === 200) {
              setTimeout(() => {
                toast.warning(response.data.message);
              }, 600);
              setWish(1);
            } else {
              // console.log("Failed To Delete Favourites");
            }
          })
          .catch((error) => {});

        setWish(1);
      }
    }
  };

  const onChange = (date) => {
    setDate(date);
    setCurrent(formatDate(date));
  };

  const changereview = () => {
    if (Review === "block") {
      setReview("none");
      setCateReview("block");
      setCalReview("none");
    } else {
      setReview("block");
      setCateReview("none");
      setCalReview("none");
    }
  };

  const Changebook = () => {
    if (calReview === "none") {
      // setReview("none");
      // setCateReview("none");
      setCalReview("block");
    } else {
      // setReview("none");
      // setCateReview("block");
      setCalReview("none");
    }
  };

  const Changeinfo = () => {
    if (info === "block") {
      setinfo("none");
    } else {
      setinfo("block");
    }
  };

  const discountdetails = async () => {
    await axios
      .get(
        `https://admin.order2go.us:3001/v1/restaurants/getRestaurantSettings?restaurant_id=${Restaurantid}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("@access_token"),
          },
        }
      )
      .then((response) => {
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);
        console.log(parsedObj.status);
        if (parsedObj.status == true) {
          setRestaurantName(parsedObj.data.name);
          setPhone(parsedObj.data.info_mobile);
          setRestaurantPicture(parsedObj.data.picture);
          setAddress1(parsedObj.data.address1);
          setAddress2(parsedObj.data.address2 + " " + parsedObj.data.address3);
          setRating("0");
          setStartTime("9.30 am");
          setEndTime("11.00 pm");
          setTax(parsedObj.data.tax);
          if (parsedObj.data?.restaurant_commission != null) {
            setDiscountPercent(
              parsedObj.data?.restaurant_commission[0].discount
            );
            setMinOrderAmount(
              parsedObj.data?.restaurant_commission[0].min_order_amount
            );

            setRating(parsedObj.data.rating);
            setStartTime(
              new Date(parsedObj.data.restaurant_time[0].start_time)
                .toLocaleTimeString()
                .toLowerCase()
                .replace(":00", "")
            );
            setEndTime(
              new Date(parsedObj.data.restaurant_time[0].end_time)
                .toLocaleTimeString()
                .toLowerCase()
                .replace(":00", "")
            );
          }
        }
      })
      .catch(function (error) {});
  };

  const checkavilable = (req, res) => {
    function changformat(date) {
      const arr = date.split("-");
      const merge = arr[2] + "-" + arr[1] + "-" + arr[0];
      return merge;
    }
    const date = changformat(current);
    axios
      .get(
        "https://api.order2go.us:3001/v1/table/findAvailableTalbes?restaurant_id=" +
          Restaurantid +
          "&date=" +
          date +
          "T" +
          time +
          ":31.587Z" +
          "&start_time=" +
          date +
          "T" +
          time +
          ":31.587Z" +
          "&end_time=" +
          date +
          "T" +
          time1 +
          ":31.587Z" +
          "&person=" +
          peoplecount,
        {
          headers: {
            "Content-Type": "application/json",
            from_mobile: "true",
            Authorization: "Bearer " + localStorage.getItem("@access_token"),
          },
        }
      )
      .then((response) => {
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);

        if (response.data.status == true) {
          var datavalue = {
            seats: peoplecount,
            restaurant_name: restaurant.name,
            restaurant_address: restaurant.address1 + " " + restaurant.address2,
            date: current,
            starttime: time,
            endtime: time1,
            ResturentId: Restaurantid,
            tabledata: response.data.data,
          };

          history.push({
            pathname: `/Booking`,
            state: { booking: datavalue },
          });
        } else if (response.data.status == false) {
          toast.info("No Any Table List");
        }
      })
      .catch((err) => {
        alert("Please Enter Valid Input");
      });
  };

  const Reserve = async (data) => {
    var currentDate = new Date().toISOString().split("T")[0];
    var selectedDate =
      "" +
      current.split("-")[2] +
      "-" +
      current.split("-")[1] +
      "-" +
      current.split("-")[0];

    var currentDateMillis = Date.parse("" + currentDate);
    var selectedDateMillis = Date.parse("" + selectedDate);

    var selected_star_time = selectedDate + "T" + time;
    var selected_end_time = selectedDate + "T" + time1;

    var start_time_millis = Date.parse("" + selected_star_time);
    var end_time_millis = Date.parse("" + selected_end_time);

    var d1 = Date.parse(new Date());
    var add_three_hours = d1 + 3 * 3600 * 1000;

    if (currentDateMillis === selectedDateMillis) {
      if (!time) {
        alert("Please select start time..");
      } else if (!time1) {
        alert("Please select end time..");
      } else {
      if (start_time_millis > add_three_hours) {
        if (end_time_millis > start_time_millis) {
          checkavilable();
        } else {
          alert("End time greater then start time...");
        }
      } else {
        alert("You can reserve table  minimum before 3 hours ");
      }}
    } else if (currentDateMillis < selectedDateMillis) {
      if (!time) {
        alert("Please select start time..");
      } else if (!time1) {
        alert("Please select end time..");
      } else {
      if (end_time_millis > start_time_millis) {
        checkavilable();
      } else {
        alert("End time greater then start time...");
      }}
    } else if (currentDateMillis > selectedDateMillis) {
      alert("Please Select today ,tomorrow,or the upComing days");
    }

    return;

    if (!localStorage.getItem("@access_token")) {
      if (window.confirm("You Want to  Reserve please login?")) {
        history.push("/login");
      } else {
        return false;
      }
    }

    const fulldate = new Date();
    const month = await ("0" + (fulldate.getMonth() + 1)).slice(-2);
    const dateday = await ("0" + fulldate.getDate()).slice(-2);
    const date = (await "") + fulldate.getFullYear() + month + dateday;
    const a = await current.split("-");
    const date2 = "" + a[2] + a[1] + a[0];

    if (date > date2) {
      alert("Please Select today ,tomorrow,or the upComing days");
      return;
    }

    function removeColon(s) {
      if (s.length == 4) s = s.replace(":", "");
      if (s.length == 5) s = s.replace(":", "");
      return parseInt(s);
    }

    function diff(s1, s2) {
      // change string (eg. 2:21 --> 221, 00:23 --> 23)
      var time1 = removeColon(s1);
      var time2 = removeColon(s2);

      // difference between hours
      var hourDiff = parseInt(time2 / 100 - time1 / 100 - 1);

      // difference between minutes
      var minDiff = parseInt((time2 % 100) + (60 - (time1 % 100)));

      if (minDiff >= 60) {
        hourDiff++;
        minDiff = minDiff - 60;
      }
      // convert answer again in string with ':'
      // const res = await(hourDiff).toString()
      const res = hourDiff.toString();
      return res;
    }

    function diffmintes(s1, s2) {
      var time1 = removeColon(s1);
      var time2 = removeColon(s2);
      var hourDiff = parseInt(time2 / 100 - time1 / 100 - 1);
      var minDiff = parseInt((time2 % 100) + (60 - (time1 % 100)));
      if (minDiff >= 60) {
        hourDiff++;
        minDiff = minDiff - 60;
      }
      // convert answer again in string with ':'
      // const res = await(hourDiff).toString()
      const res = minDiff.toString();
      return res;
    }

    var d1 = new Date();
    var d2 = new Date();
    d1.setHours(+d2.getHours() + 3);
    d1.setMinutes(new Date().getMinutes());
    const additionaltime = d1.getHours() + ":" + d1.getMinutes();
    const threehours = diff(additionaltime, time);
    const currenttime = diff(time, time1);

    const threehoursMintes = diffmintes(additionaltime, time);
    const currenttimeMintes = diffmintes(time, time1);

    console.log(additionaltime);
    console.log(time1);
    console.log(time);
    console.log(threehours);
    console.log(threehoursMintes);
    console.log(currenttime);
    console.log(currenttimeMintes);
    console.log(currenttime >= 0 && currenttimeMintes >= 0);

    if (date == date2) {
      if (threehours >= 0 && threehoursMintes >= 0) {
        if (currenttime >= 0 && currenttimeMintes >= 0) {
          checkavilable();
        } else {
          if (time1 == time) {
            alert(`Start time ${time}  & end time ${time1} can't be same...`);
          }
        }
      } else {
        alert(
          `You can reserve table  minimum before 3 hours  ${additionaltime} Your time ${time} ...`
        );
      }
    } else {
      if (currenttime > 0) {
        checkavilable();
      } else {
        if (time1 == time) {
          alert(`Start time ${time}  & end time ${time1} can't be same...`);
        } else {
          alert("End time greater then start time...");
        }
      }
    }
  };

  const getDatas = async () => {
    var values = await localStorage.getItem("@cartItem");
    if (values === null) {
      setCartData([]);
    } else {
      var data = [];
      data.push(values);
      const mydata = JSON.parse(data);
      setCartData(mydata);
    }
  };

  const onChangeProductQuantity = (index, event, value1) => {
    const value = event.target.value;
    const valueInt = parseInt(value);
    const cloneProducts = [...products];
    if (value1 == "+") {
      discountdetails();
      cloneProducts[index].qty = products[index].qty + 1;
    } else if (value1 == "-") {
      cloneProducts[index].qty =
        products[index].qty == 1
          ? onRemoveProduct(index)
          : products[index].qty - 1;
    }

    setProducts(cloneProducts);

    localStorage.setItem("@cartItem", JSON.stringify(cloneProducts));
  };

  /////REMOVE CART
  const onRemoveProduct = (i) => {
    const filteredProduct = products.filter((product, index) => {
      return index != i;
    });
    if (!localStorage.setItem("@cartItem", JSON.stringify(filteredProduct))) {
      if (window.confirm("You Want to  remove cart item?")) {
        toast.error("cart Removed Successfully");
        // return true;
      } else {
        return 1;
      }
    }
    var i = 0;
    var test = setInterval(() => {
      i++;
      setProducts(filteredProduct);
      localStorage.setItem("@cartItem", JSON.stringify(filteredProduct));
      if (i < 3) {
        setCartsize(filteredProduct.length);
        clearInterval(test);
      }
    }, 1000);
  };

  const CheckAlreadyCart = async (props) => {
    setCartsize(products.length);
    setResetCart({
      categoryID: props.category_id,
      description: props.desc,
      discount: discountPercent,
      id: props.id,
      menuID: props.menu_price.menu_id,
      name: props.name,
      picture: props.picture,
      price: props.menu_price.price,
      qty: count,
      tax: taxdetail,
      restaurantID: props.restaurant_id,
      subCategoryID: props.sub_category_id,
      min_order_amount: MinOrderAmount,
    });

    const idToRemove = await props.id;
    const idToRemove1 = await props.restaurant_id;

    if (cartData.length === 0) {
      storeCartData(props);
    } else {
      var item = await products.find((item) => item.id === idToRemove);
      var item1 = await products.find(
        (item) => item.restaurantID != idToRemove1
      );

      if (await item) {
        toast.info("ITEM ALREADY ADDED TO CART");
      } else if (await item1) {
        toggleModal();
      } else {
        storeCartData(await props);
        toast.success("ITEM ADDED TO CART Successfully");
      }
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const Change = () => {
    return (
      <Popup
        open={isOpen}
        className="popup-modal-container-box"
        modal
        closeOnDocumentClick={false}
      >
        <div className="_2835q">
          <div className="tdLYq">
            <div className="zmgWM">
              <div className="R4-yb">Items already in cart</div>
              <div className="VzKYj">
                <div className="_3XWJf">
                  Your cart contains items from other restaurant. Would you like
                  to reset your cart for adding items from this restaurant?
                </div>
              </div>
            </div>
          </div>
          <div className="_3G9po">
            <button className="b0G1m" onClick={toggleModal}>
              No
            </button>
            <span className="U5Ri6" />
            <button className="_2-MHS" onClick={() => storeCartData()}>
              Yes, start afresh
            </button>
          </div>
        </div>
      </Popup>
    );
  };

  const storeCartData = async (props) => {
    if (props === undefined) {
      setIsOpen(!isOpen);
      localStorage.removeItem("@cartItem");
      setProducts([]);
      var newCartItem = ResetCart;
    } else {
      var newCartItem = {
        categoryID: props.category_id,
        description: props.desc,
        discount: discountPercent,
        id: props.id,
        menuID: props.menu_price.menu_id,
        name: props.name,
        picture: props.picture,
        price: props.menu_price.price,
        qty: count,
        tax: taxdetail,
        restaurantID: props.restaurant_id,
        subCategoryID: props.sub_category_id,
        min_order_amount: MinOrderAmount,
      };
    }
    let existingTransactions = await getCartData();
    if (existingTransactions.length === 0) {
      var newarr = existingTransactions;
    } else {
      var newarr = JSON.parse(existingTransactions);
    }
    const updatedTransactions = [...newarr, newCartItem];
    await localStorage.setItem(
      "@cartItem",
      JSON.stringify(updatedTransactions)
    );
    setProducts(updatedTransactions);
    setCartsize(updatedTransactions.length);
    setCartData(updatedTransactions);
  };

  const getCartData = () => {
    let transactions = localStorage.getItem("@cartItem");
    if (transactions) {
      return transactions;
    } else {
      return new Array();
    }
  };

  const setCartDiscountDetails = async () => {
    if (products.length > 0) {
      await axios
        .get(
          `https://admin.order2go.us:3001/v1/restaurants/getRestaurantSettings?restaurant_id=${products[0].restaurantID}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("@access_token"),
            },
          }
        )
        .then((response) => {
          var stringified = JSON.stringify(response.data);
          var parsedObj = JSON.parse(stringified);
          console.log(parsedObj.status);
          if (parsedObj.status == true) {
            for (let index = 0; index < products.length; index++) {
              products[index].tax = parsedObj.data.tax;
            }
            if (parsedObj.data?.restaurant_commission != null) {
              for (let index = 0; index < products.length; index++) {
                products[index].discount =
                  parsedObj.data?.restaurant_commission[0].discount;
                products[index].min_order_amount =
                  parsedObj.data?.restaurant_commission[0].min_order_amount;
              }
            } else {
              for (let index = 0; index < products.length; index++) {
                products[index].discount = 0;
                products[index].min_order_amount = 0;
              }
            }
          }
        })
        .catch(function (error) {});

      await localStorage.setItem("@cartItem", JSON.stringify(products));
    } else {
      if (CLONE_PRODUCTS.length > 0) {
        window.location.reload();
      }
    }
  };

  const Cartpropsdata = {
    products,
    setProducts,
    onChangeProductQuantity,
    total,
    discount,
    tax,
    formatCurrency,
    subTotal,
    user,
  };

  const Resturendheader = {
    current1,
    setCurrent1,
    Changeinfo,
    cateReview,
    setCateReview,
    Rating,
    StartTime,
    EndTime,
    restaurant,
    discountPercent,
    changereview,
    Changebook,
    wish,
    Fav,
    address1,
    address2,
    restaurantPicture,
    restaurantName,
    phone,
    isFav,
  };

  const Categorypropsdata = {
    info,
    restaurant,
    calReview,
    onChange,
    date,
    current,
    peoplecount,
    setPeoplecount,
    Reserve,
    Review,
    CheckAlreadyCart,
    settings,
    Categorylist1,
    Categorylist12,
    cateReview,
    categoryname,
    categorydetail,
    subcategory_list1,
    subCategory,
    Category,
    cartData,
    setTime,
    setTime1,
    status,
    setstatus,
    Restaurantid,
  };

  return (
    <div>
      <ToastContainer />
      {Change()}
      <main style={{ transform: "none" }}>
        <Header Resturent={restaurant} Resturendheader={Resturendheader} />
        <div className="container margin_detail" style={{ transform: "none" }}>
          <div className="row" style={{ transform: "none" }}>
            <Categoryslide Categorypropsdata={Categorypropsdata} />
            <Cart Cartpropsdata={Cartpropsdata} />
          </div>
        </div>
      </main>
    </div>
  );
};
export default Resturent;
