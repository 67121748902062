import React from "react";
import { animateScroll as scroll } from "react-scroll";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";

const Footer = () => {

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Order2go</FooterLinkTitle>
              <FooterLink to="/">high street</FooterLink>
              <FooterLink to="/">pernambut</FooterLink>
              <FooterLink to="/">Chennai 600003, Tamil Nadu</FooterLink>
            </FooterLinkItems>
            
            <FooterLinkItems>
              <FooterLinkTitle>Useful Links</FooterLinkTitle>
              <FooterLink to="/">Home</FooterLink>
              <FooterLink to="/">About us</FooterLink>
              <FooterLink to="/">Services</FooterLink>
              <FooterLink to="/">Privacy policy</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            {/* <FooterLinkItems>
              <FooterLinkTitle>Our Services</FooterLinkTitle>
              <FooterLink to="/">Provides Stability</FooterLink>
              <FooterLink to="/">Needed For Equality</FooterLink>
              <FooterLink to="/">A Safer World</FooterLink>
              <FooterLink to="/">Confidence</FooterLink>
            </FooterLinkItems> */}
            {/* <FooterLinkItems>
              <FooterLinkTitle>Flexability</FooterLinkTitle>
              <FooterLink to="/">Provides Stability</FooterLink>
              <FooterLink to="/">Needed For Equality</FooterLink>
              <FooterLink to="/">A Safer World</FooterLink>
              <FooterLink to="/">Confidence</FooterLink>
            </FooterLinkItems> */}
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/">
            Order2go
            </SocialLogo>
            <WebsiteRights>
            Order2go© {new Date().getFullYear()} All rights reserved
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="/"
                
                aria-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink href="/"  aria-label="Instagram">
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink href="/"  aria-label="YouTube">
                <FaYoutube />
              </SocialIconLink>
              <SocialIconLink href="/"  aria-label="Twitter">
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink href="/"  aria-label="LinkedIn">
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
