import React from "react";
import { Card, CardText, CardBody, CardTitle, CardImg } from "reactstrap";
import "./style/style.css";

function Banner() {

  return (
    <div>
      <div
        className="row cardlower"
        style={{ display: "flex",justifyContent:"space-around"}}
      >
        
        <Card className="bgcard" style={{marginTop:"3%",height:"280px",borderRadius:"40px"}}>
          <CardImg style={{ width: "18rem" }} variant="top" />

          <CardBody>
            <CardTitle className="bgt">Are you a Restaurant Owner?</CardTitle>
            <CardText className="bgpara">
              Join Us to increase your online visibility. You'll have access to
              even more customers who are looking to enjoy your tasty dishes at
              home.
            </CardText>
            <div className="form-group text-center">
                <input
                  type="submit"
                  className="btn_1"
                  value="contact us"
                  id="submit-register"
                />
              </div>
          </CardBody>
        </Card>



       <div>          <div className=" card" style={{ padding: "2px 50px",margin:"2% 6% ",}}>
            <div className="text-center add_bottom_15">
              <h4>Contact us</h4>
            </div>
            <div id="message-register"></div>
            <form style={{margin:"10px auto 0"}}  method="post" action="#" id="register">
              <h6>Personal Info</h6>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      name="name_register"
                      id="name_register"
                      required
                    />
                  </div>
                </div>
              </div>
              <h6>E-mail</h6>
              <div className="row add_bottom_15">
                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                      name="email_register"
                      id="email_register"
                      required
                    />
                  </div>
                </div>
              </div>

              <h6>Restaurant Info</h6>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Restaurant Name"
                      name="restaurantname_register"
                      id="restaurantname_register"
                       required
                    />
                  </div>
                </div>
              </div>
              <h6>Address</h6>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      type="textarea"
                      className="form-control"
                      placeholder="Address"
                      name="address_register"
                      id="address_register"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row add_bottom_15">
                <div  className="col-md-6">
                  <div style={{paddingTop:"10px"}} className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                      name="city_register"
                      id="city_register"
                      required
                    />
                  </div>
                </div>
                <div  className="col-md-6">
                  <div className="form-group">
                  <select
                      className="form-control"
                      name="country_register"
                      id="country_register"
                    >
                      <option value="">Country</option>
                      <option value="Europe">Europe</option>
                      <option value="Asia">Asia</option>
                      <option value="Unated States">Unated States</option>
                    </select>
                  </div>
                </div>
        

          
              </div>

              <div className="form-group text-center">
                <input
                  type="submit"
                  className="btn_1"
                  value="Submit"
                 
                />
              </div>
            </form>
          </div></div>

       


      </div>
    </div>
  );
}

export default Banner;
