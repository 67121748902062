import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import StepProgressBar from "react-step-progress";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import "./style/resveration.css";
import Sidebar from "../Sidebar";
function step2Validator() {
  // return a boolean
}
function step3Validator() {
  // return a boolean
}

function onFormSubmit() {
  // handle the submit logic here
  // This function will be executed at the last step
  // when the submit button (next button in the previous steps) is pressed
}
const dateCallback = (selectedDate) => {
  const currentDate = selectedDate || Date;
  const dateis = "" + currentDate;
  const arr1 = dateis.split("T");
  const arr2 = arr1[0];
  const time = arr1[1];
  const arr3 = time.split(":");
  var arr4 = arr3[0];
  const arr5 = arr3[1];
  const arr6 = arr4 > 12 ? "PM" : "AM";
  arr4 = arr4 > 12 ? arr4 - 12 : arr4;
  const myDate =
    " " +
    arr2 +
    " " +
    arr4 +
    ":" +
    arr5 +
    "" +
    arr6 +
    " " +
    new Date(currentDate).toLocaleTimeString();
  return new Date(currentDate).toDateString();
};
const Reservation = () => {
  const [reservation, setreservation] = useState([]);
  const [name, setname] = useState(0);
  useEffect(() => {
    async function fetchMyAPI() {
      var uData = JSON.parse(localStorage.getItem("@user_data"));
      var rId = uData.restaurant_id;
      let response = await fetch(
        `https://api.order2go.us:3001/v1/reservation/findAll?restaurant_id=` +
          rId,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("@access_token"),
          },
        }
      );
      response = await response.json();
      var stringified = JSON.stringify(response);
      var parsedObj = JSON.parse(stringified);
      if (parsedObj.status == true) {
        setreservation(parsedObj.data);
      } else {
        console.log("Failed To Get Reservation data");
      }
    }
    fetchMyAPI();
  }, []);

  const cancelOrder = async (item) => {
    axios
      .post(
        "https://api.order2go.us:3001/v1/reservation/updateReservationStatus",
        {
          id: item.id,
          status: 0,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer " + (await localStorage.getItem("@access_token")),
          },
        }
      )
      .then((response) => {
        var stringified = JSON.stringify(response);
        var parsedObj = JSON.parse(stringified);
        if (response.data.status == true) {
          alert("Order Cancelled Sucess");
          toast.success("Deleted Successfully");
          window.location.reload(false);
        } else if (response.data.status == false) {
          alert("Order Canceled failure");
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <div className="wrapper d-flex align-items-stretch">
      <Sidebar Color={3} />
      {reservation.length > 0 ? (
        <div id="content" className="p-4">
          {reservation.map((item, index) => (
            <main>
              <Card
                id="rlcard"
                style={{
                  marginTop: "30px",
                  height: "200px",
                  width: "70%",
                  position: "relative",
                  left: "150px",
                  textAlign: "center",
                }}
                className="text-center"
              >
                <Card.Header
                  id="rlcardheader"
                  style={{ backgroundColor: "#eeeeee" }}
                >
                  <ul
                    style={{
                      textAlign: "center",
                      fontSize: "17px",
                      fontWeight: "500",
                      height: "5px",
                    }}
                  >
                    <li style={{ fontWeight: "600" }}>
                      {item.restaurant.name}
                    </li>
                  </ul>
                </Card.Header>
                <Card.Body>
                  <Card.Title id="rlcardtitle">
                    <ul
                      id="rlcardtitleul"
                      style={{
                        fontSize: "15px",
                        width: "400px",
                        textAlign: "left",
                      }}
                    >
                      <li>
                        BookingDate{" "}
                        <span style={{ position: "relative", left: "25px" }}>
                          {dateCallback(item.date)}
                        </span>
                      </li>
                      <li>
                        Start Time{" "}
                        <span style={{ position: "relative", left: "40px" }}>
                          {new Date(
                            item.start_time.replace("Z", "")
                          ).toLocaleTimeString()}
                        </span>
                      </li>
                      <li>
                        End Time{" "}
                        <span style={{ position: "relative", left: "50px" }}>
                          {new Date(
                            item.end_time.replace("Z", "")
                          ).toLocaleTimeString()}
                        </span>
                      </li>
                      <li>
                        Guest{" "}
                        <span style={{ position: "relative", left: "80px" }}>
                          {item.person}
                        </span>
                      </li>
                    </ul>
                  </Card.Title>
                  <Card.Text
                    id="rlcardtext"
                    style={{
                      width: "500px",
                      float: "right",
                      left: "50px",
                      position: "relative",
                      bottom: "140px",
                      fontSize: "15px",
                    }}
                  >
                    {item.status_text !== "Cancelled" ? (
                      <StepProgressBar
                        // startingStep={0}
                        startingStep={
                          item.status_text === "Waiting"
                            ? 0
                            : item.status_text === "Approved"
                            ? 1
                            : 0
                        }
                        onSubmit={onFormSubmit}
                        steps={[
                          {
                            label: "Waiting",
                            name: "step 1",
                            // content: step1Content
                          },
                          {
                            label: "Approved",
                            name: "step 2",
                            // content: step2Content,
                            validator: step2Validator,
                          },
                        ]}
                      />
                    ) : (
                      <h3
                        id="cancelledstatus"
                        style={{
                          fontSize: "22px",
                          marginTop: "7%",
                          color: "#f01d1d",
                        }}
                      >
                        {item.status_text}
                      </h3>
                    )}

                    <div
                      id="rlcardbuttons"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        position: "relative",
                        bottom: "50px",
                        fontSize: "15px",
                        left: "160px",
                        display:
                          item.status_text === "Waiting" ? "block" : "none",
                      }}
                    >
                      <Button
                        className="viewall"
                        onClick={() => cancelOrder(item)}
                        style={{
                          borderRadius: "10px",
                          color: "white",
                          backgroundColor: "#f01d1d",
                          height: "30px",
                          padding: "3px",
                          textAlign: "center",
                          fontSize: "14px",
                          fontWeight: "500",
                          textTransform: "capitalize",
                          position: "relative",
                          bottom: "2px",
                        }}
                        variant="outline -secondary"
                      >
                        cancel
                      </Button>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </main>
          ))}
        </div>
      ) : (
        <h4 style={{ margin: "30%" }}>No Data Found</h4>
      )}
    </div>
  );
};
export default Reservation;
