import React, { useEffect, useState } from "react";
// import ReactStars from "react-rating-stars-component";
import "./style/style.css";
import "./style/newcard.css"
import { useHistory } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import ReactStars from "react-rating-stars-component";
import { FaStarHalfAlt,FaStar,FaRegStar } from "react-icons/fa";

import Header from "../Header";
const InfoSection = () => {
  const [restaurant, setRestaurant] = useState([]);
  // const [rating, setrating] = useState(history.location.state.detail.rating);
  const [count, setCount] = useState(6);
  const [block, setBlock] = useState("block");
  const [FilteredDataSource, setFilteredDataSource] = useState([]);
  const history = useHistory();
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await fetch(
        `https://api.order2go.us:3001/v1/restaurants/findAll`,
        {
          headers: {
            "Content-Type": "application/json",
            from_mobile: "true",
          },
        }
      );
      response = await response.json();
      var stringified = JSON.stringify(response);
      var parsedObj = JSON.parse(stringified);
      if (parsedObj.status === true) {
        setRestaurant(parsedObj.data);
        setFilteredDataSource(parsedObj.data);
      } else {
        // alert("Failed To Get Restaurant data");
      }
    }

    fetchMyAPI();
  }, []);

  const searchFilterFunction = (text) => {
    // Check if searched text is not blank
    if (text) {
      const newData = restaurant.filter(function (item) {
        const itemData = item.name ? item.name.toUpperCase() : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setFilteredDataSource(newData);
    } else {
      setRestaurant(restaurant);
    }
  };

  const viewRest = (params) => {
    history.push({
      pathname: `/Resturent/${params.id}`,
      state: { restaurant: params, isFav:false },
    });
  };

  const viewmore = () => {
    setCount(restaurant.length);
    setBlock("none");

  };

  return (
    <div>
      <Header search={searchFilterFunction} />
      <h1
        style={{
          margin: "29px 0px",
        }}
      ></h1>
      <div className="container" style={{ margin: "auto" }}>
        <div className="row">



        {/* <div class="container"> */}


{/* ////// */}



{/* ////// */}





{FilteredDataSource.slice(0, count).map((data, index) => (
         
<div style={{ height: "300px" }}
              className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
<div style={{borderRadius:"12px"}} class="card">
<div class="card__image"

onClick={() => viewRest(data)}
style={{cursor:"pointer"}}

>

<figure></figure>
<img
                    src={`https://api.order2go.us:3001/${
                      data.picture === ""
                        ? "uploads/images/Image-1637340817630.jpg"
                        : data.picture
                    }`}
                    className="img-fluid lazy loaded"
                    alt=""
                    data-was-processed="true"
                  />
                  {/* <a className="strip_info" onClick={() => viewRest(data)}>
                    <div className="item_title">
                      <h3></h3>
                      <small></small>
                    </div>
                  </a> */}

</div>
<div class="card__info">
   <div class="car__info--title">
      <h3><div>{data.name.substring(0, 20)}</div></h3>
      <p>{data.address3 + ", " + data.address2}</p>
   </div>
   <div class="card__info--price">
   <strong
 style={{
backgroundColor: "white",
                          color: "black",
                          borderRadius: "10px",
                          marginLeft: "-5%",
                          height: "30px",
                          boxShadow: "0 0 50px #d9d9d9",
                          padding: "1px 1px 0px 1px",
                        }}
                      >
                        {" "}



  
<span
                          style={{
                            Left: "15px",
                            fontSize: "15px",
                            fontWeight: "990",
                          }}
                        >
 <ReactStars size={16}
emptyIcon={<FaRegStar />}
halfIcon={<FaStarHalfAlt />}
filledIcon={<FaStar />}
value={4}

/> 
{/* <a>{data}</a> */}

                        </span>
                        {/* <span
                        style={{
                          textAlign: "center",
                          backgroundColor: "#159848",
                          color: "white",
                          borderRadius: "5px",
                          height: "23px",
                          padding: "6px",
                          marginLeft: "5%",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        <a
                          onClick={() => viewRest(data)}
                          style={{ color: "white" }}
                        >
                          {" "}
                          OPEN{" "}
                        </a>
                      </span> */}
   </strong>
      {/* <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span>
      <span class="fa fa-star checked"></span> */}
   </div>
</div>
{/* </div> */}



</div>
</div>

          ))}
        </div>










{FilteredDataSource.length != 0?(
        <div style={{ display: block, textAlign: "center" }}>
          <button className="button-62" onClick={() => viewmore()}>
            View More
          </button>
        </div>

):(
  <div>
  </div>
)}
      </div>
    </div>
  );
};
export default InfoSection;
