import styled from 'styled-components';
import {Link as LinkRouter} from 'react-router-dom';


export const Nav = styled.nav`
    background:#fff;
    height: 70px;
    
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: stretch;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 100%;
`;



export const NavLogo = styled(LinkRouter)`
    // color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;
    background: linear-gradient(
        to right, 
        #e94057, 
        #f27121
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
`;


export const MobileIcon = styled.div`
    display: none;
 @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
`;

export const NavMenu = styled.ul`

    display: flex;
    list-style: none;
    margin-left:auto ;
    // @media screen and (max-width: 768px) {
    //     display: none;
    // }
`;



export const NavItem = styled.li`
    height: 80px;
    
`;

export const NavLinks = styled.div`
    color: #fff;    
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    //bottom green bar when selected
    &.active {
        border-bottom: 3px solid #01bf71;
    }
`;

export const Link = styled(LinkRouter)`
    color: black !important;    
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    text-decoration:none
    cursor: pointer;
    text-decoration:none;
    font-size:21px;


    @media screen and (max-width: 768px) {
        font-size:15px;

    }

    //bottom green bar when selected
    &.active {
        border-bottom: 3px solid #01bf71;

    }
    &:hover {
        text-decoration:none;
    }
`;


