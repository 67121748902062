import React, { useState } from 'react';
import axios from 'axios';
import { toast} from "react-toastify";
import { Card, CardText, CardBody, CardTitle, CardSubtitle, Cardbutton, CardImg } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

import './log.css'; 

function Forget() {






  const history = useHistory([]);

  const [phonenumber, setphonenumber] = useState([]);

   const formsend = (e) => {

   axios.post(
        "https://api.order2go.us:3001/v1/users/forgetPassword",
        {
          phone: phonenumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
           Authorization: "Bearer " + (localStorage.getItem("@access_token")),
  },
        }
      )
      .then((response) => {
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);

        if (parsedObj.status === true) {
          alert(parsedObj.message)
          history.push({
            pathname: "/otp",
          state:{ user_id:parsedObj.data.user_id }
          })
        } 
        else {
          toast.info("check your number ");
        }
      })
      .catch((error) => {
        toast.info(error.message);

      });
  };

 return (
 <div>
      <div>
        <div
          style={{
            backgroundImage: "url(https://images.pexels.com/photos/5695943/pexels-photo-5695943.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center ,center",
            height: "657px",
          }}>

          <Card className='forgetcard'>
          <CardBody>
              <div >
                <div >
                  <CardTitle className='forgettitle'> Forget Password!</CardTitle>
                  <CardText className='forgetpara'> Enter your phone number and will <span style={{ color: "rgb(236, 52, 89)", fontWeight: "600" }}> send </span> you instruction  on how to reset it.</CardText>
                  <div className='forgetcolumn'>
                    <div className="input-group mb-2">
                      <div >
                        <span className="input-group-text"></span>
                      </div>
                      <input type="text" onChange={(e) =>{setphonenumber(e.target.value) }} className="form-control" placeholder="Enter mobile number" />
                    </div>
                    <div >
                      <button onClick={(e) => formsend(e)} className="forgetbutton2">

                        {/* <a href='/otp'style={{color:"#fff"}}> */}
    Send
    {/* </a>  */}
    
    </button>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  )
}
export default Forget;