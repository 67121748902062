import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { MdDeliveryDining, MdOutlineRestaurantMenu, MdFastfood, MdOutlineAccountBalanceWallet, MdRateReview, MdLogout } from "react-icons/md";
import { MdOutlineMenuOpen } from "react-icons/md";
import { FaTicketAlt } from "react-icons/fa";
import { UserContext } from "../../UserContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Sidebar = props => {

  const [username, setUsername] = useState("");
  const [profile, setProfile] = useState("");
  const history = useHistory();
  const [active, setActive] = useState("");
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (!localStorage.getItem("@user_data")) {
      history.push("/")
    }
    setUsername(JSON.stringify(user.name));
    setProfile(JSON.stringify(user.picture));
  }, []);

  const Logout = () => {
    localStorage.removeItem("@user_data")
    localStorage.removeItem("@access_token")
    localStorage.removeItem("@cartItem")
    setUser("")
    history.push(`/`)

  }




  return (
    <nav id="sidebar" style={{ position: "sticky" }} className={active}>
      <ToastContainer />
      <div className="custom-menu">
        <div id="sidebarCollapse" style={{ color: "black", padding: "2px", cursor: "pointer" }} onClick={() => {
          active === "" ? setActive("active") : setActive("")
        }}> <MdOutlineMenuOpen
            style={{ cursor: "pointer" }}
            onClick={() => {
              active === "" ? setActive("active") : setActive("")
            }} fontSize={"23px"} />
        </div>
      </div>
      <div className="img bg-wrap text-center py-4" style={{ height: "200px", }}>
        <div className="user-logo">
          <div className="img" style={{ backgroundImage: `url(https://api.order2go.us:3001/${profile?.replaceAll('"', '')})` }}></div>
          <h3>{username?.split('"')[1]}</h3>
        </div>
      </div>
      <ul className="list-unstyled components mb-5">
        <li className="active" style={{ backgroundColor: props.Color === 1 ? "#fff" : "none" }}>
          <a href="/Cardlist" style={{ color: props.Color === 1 ? "black" : "none" }}><CgProfile style={{ padding: "4px", fontSize: "28px" }} /> Manage profile</a>
        </li>
        <li className="" style={{ backgroundColor: props.Color === 2 ? "#fff" : "none" }}>
          <a href="/myorder" style={{ color: props.Color === 2 ? "black" : "none" }}><MdDeliveryDining style={{ padding: "4px", fontSize: "28px" }} />My Orders</a>
        </li>
        <li className="/" style={{ backgroundColor: props.Color === 3 ? "#fff" : "none" }} >
          <a href="/Reservation" style={{ color: props.Color === 3 ? "black" : "none" }}><FaTicketAlt style={{ padding: "4px", fontSize: "28px" }} /> My Reservation
          </a>
        </li>

        <li style={{ backgroundColor: props.Color === 4 ? "#fff" : "none" }}>
          <a href="/favrestaurant" style={{ color: props.Color === 4 ? "black" : "none" }} ><MdOutlineRestaurantMenu style={{ padding: "4px", fontSize: "28px" }} /> fav Restaurants</a>
        </li>

        <li style={{ backgroundColor: props.Color === 5 ? "#fff" : "none" }} >
          <a href="/Foodlist" style={{ color: props.Color === 5 ? "black" : "none" }} ><MdFastfood style={{ padding: "4px", fontSize: "28px" }} />Food List</a>
        </li>

        <li style={{ backgroundColor: props.Color === 6 ? "#fff" : "none" }}>
          <a href="/Card" style={{ color: props.Color === 6 ? "black" : "none" }}><MdOutlineAccountBalanceWallet style={{ padding: "4px", fontSize: "28px" }} />Account Details</a>
        </li>
        <li style={{ backgroundColor: props.Color === 7 ? "#fff" : "none" }}>
          <a href="/sidebarreview" style={{ color: props.Color === 7 ? "black" : "none" }} ><MdRateReview style={{ padding: "4px", fontSize: "28px" }} />   Review</a>
        </li>
        <li onClick={() => Logout()}>
          <a href="#" ><MdLogout style={{ padding: "4px", fontSize: "28px" }} fontSize={"22px"} onClick={() => Logout()} />Log Out</a>
          <ToastContainer
            style={{ zIndex: 10000 }}
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnHover
          />
        </li>
      </ul>
    </nav>
  )
}

export default Sidebar;