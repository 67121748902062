import React, { useState, useLayoutEffect, useEffect,useContext } from "react";
import { useHistory } from "react-router-dom";
import "./style.css";
// import Restaurant from "./Favourite/Restaurant";
import Sidebar from "./Sidebar";
import Update from "./Pages/Update";
import { UserContext } from "../../UserContext";

const Index = () => {
  const history = useHistory();
  const {user,setUser} = useContext(UserContext)
  useEffect(() => {
 if(user == null){
  history.push('/')
 }
}, []);


return (
    <div className="wrapper d-flex align-items-stretch">
      <Sidebar Color={1} />
      <div id="content" className="p-4">
      <Update />
      </div>
    </div>
  );
};


export default Index;
