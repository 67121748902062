import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-credit-cards/es/styles-compiled.css";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import CardDisplay from "react-credit-card-display";
import axios from "axios";

import OrderCart from "./OrderCart";

const PaymentConfirm = () => {
  const [name, SetName] = useState("");
  const [month, SetMonth] = useState("");
  const [expiry, SetExpiry] = useState("");
  const [cvv, SetCvv] = useState("");

  const handleDate = (e) => {
    SetMonth(e.target.value);
    SetExpiry(e.target.value);
  };

  const handleExpiry = (e) => {
    SetExpiry(month.concat(e.target.value));
  };

  const [cardData, setCardData] = useState([]);
  const [show, setShow] = useState(false);
  const [individual, setindividual] = useState("");
  const [number, setNumber] = useState("");
  const PRODUCTS =
    JSON.parse(localStorage.getItem("@cartItem")) == null
      ? []
      : JSON.parse(localStorage.getItem("@cartItem"));

  const [Condition, setCondition] = useState(false);
  const CLONE_PRODUCTS = JSON.parse(JSON.stringify(PRODUCTS));
  const [products, setProducts] = useState(CLONE_PRODUCTS);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [taxdetail, setTax] = useState(0);
  const subTotal = products.reduce((total, product) => {
    return total + product.price * +product.qty;
  }, 0);
  const discount = (subTotal * discountPercent) / 100;
  const tax = (subTotal * taxdetail) / 100;
  const total = subTotal;
  const history = useHistory();

  useEffect(() => {
    if (products.length === 0) {
    } else {
      discountdetails();
    }
    OldData();
  }, []);

  const discountdetails = async () => {
    await axios
      .get(
        `https://admin.order2go.us:3001/v1/restaurants/getRestaurantSettings?restaurant_id=${products[0].restaurantID}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("@access_token"),
          },
        }
      )
      .then((response) => {
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);
        if (parsedObj.status == true) {
          setTax(0);
          // setTax(parsedObj.data.tax)
          if (parsedObj.data.restaurant_commission != null) {
            setDiscountPercent(0);
            // setDiscountPercent(parsedObj.data.restaurant_commission[0].discount)
          }
        }
      })
      .catch(function (error) {});
  };

  const OldData = async () => {
    const values = await localStorage.getItem("@card_details");
    if (values === null) {
    } else {
      var data = [];
      data.push(values);
      const mydata = JSON.parse(data);
      setCardData(mydata);
    }
  };

  function formatCurrency(value) {
    return Number(value).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  const onChangeProductQuantity = (index, event, value1) => {
    const value = event.target.value;
    const valueInt = parseInt(value);
    const cloneProducts = [...products];
    if (value1 == "+") {
      cloneProducts[index].qty = products[index].qty + 1;
    } else if (value1 == "-") {
      cloneProducts[index].qty =
        products[index].qty == 1
          ? onRemoveProduct(index)
          : products[index].qty - 1;
    }
    setProducts(cloneProducts);
  };

  const onRemoveProduct = (i) => {
    const filteredProduct = products.filter((product, index) => {
      return index != i;
    });
    localStorage.setItem("@cartItem", JSON.stringify(filteredProduct));
    toast.error("cart Removed Successfully");
    var i = 0;
    var test = setInterval(() => {
      i++;
      setProducts(filteredProduct);
      if (i < 3) {
        clearInterval(test);
      }
    }, 1000);
  };

  const Cartpropsdata = {
    products,
    onChangeProductQuantity,
    total,
    discount,
    tax,
    formatCurrency,
    subTotal,
  };

  const Authsubmit = () => {
    var isMin =
      Cartpropsdata.subTotal >=
      Cartpropsdata.products[0]?.min_order_amount;
    var totalAmount = 
      isMin
        ? Cartpropsdata.formatCurrency( Cartpropsdata.subTotal -(Cartpropsdata.subTotal * Cartpropsdata.products[0].discount) / 100 +
              (Cartpropsdata.subTotal * Cartpropsdata.products[0].tax) / 100 )
        : Cartpropsdata.formatCurrency(Cartpropsdata.subTotal)
    
    // alert(totalAmount.split("$")[1])
    // return
    if (!number || !expiry || !cvv) {
      alert("Please Enter All Required Fields");
      return;
    }

    if (Condition) {
      CheckAlreadyCardStored();
    }

    const object = {
      account: number,
      expiry: expiry,
      amount: totalAmount.split("$")[1],//subTotal - discount + tax
      cvv: cvv,
    };

    if (cvv.length > 2) {
      axios
        .post("https://admin.order2go.us:3001/v1/payment/auth", object, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("@access_token"),
          },
        })
        .then((res) => {
          if (res.data.status == true) {
            Ordercreate(res.data);
          } else {
            toast.info(res.data.message);
          }
        })
        .catch((err) => {
          toast.info(err.message);
        });
    } else {
      toast.info("Please Enter CVV Number");
    }

  };

  const getCardData = async () => {
    let transactions = await localStorage.getItem("@card_details");
    if (transactions) {
      return JSON.parse(transactions);
    } else {
      return [];
    }
  };

  const storeCardData = async () => {
    let existingTransactions = await getCardData();
    const updatedTransactions = [
      ...existingTransactions,
      {
        Name: name,
        number: number,
        Month: month,
        Year: expiry,
      },
    ];
    await localStorage.setItem(
      "@card_details",
      JSON.stringify(updatedTransactions)
    );
    OldData();
    setCardData(updatedTransactions);
  };

  const CheckAlreadyCardStored = async () => {
    const idToRemove = number;
    if ((await idToRemove) !== null) {
      if ((await cardData.length) === 0) {
        storeCardData();
      } else {
        var item = await cardData.find((item) => item.number == idToRemove);
        var i = 0;
        if (await item) {
          toast.info("CARD Already Added");
          return;
        } else {
          storeCardData();
          toast.success("ITEM ADDED TO CART Successfully");
        }
      }
    } else {
      toast.error("PLEASE FILL THE INPUT");
    }
  };

  const Ordercreate = async (props) => {
    var isMin = Cartpropsdata.subTotal >= Cartpropsdata.products[0]?.min_order_amount;
    var order_sub_total = Cartpropsdata.subTotal;
    var order_tax = isMin ? (Cartpropsdata.subTotal * Cartpropsdata.products[0]?.tax) / 100 : 0;
    var order_discount = isMin ? (Cartpropsdata.subTotal * Cartpropsdata.products[0]?.discount) / 100 : 0;
    var order_orverall_total = order_sub_total - order_discount + order_tax

    // alert(order_sub_total)
    // alert(order_tax)
    // alert(order_discount)
    // alert(order_orverall_total)

    // return;

    const data = await props.data;
    let strdata = [];
    await products.map((data, index) => {
      let itemData = {
        menu_id: data.menuID,
        qty: data.qty,
        price: data.price,
      };
      strdata.push(itemData);
    });

    if (data.authcode != "undefined") {
      const object = {
        authcode: data.authcode,
        retref: data.retref,
        discount: order_discount,
        tax: order_tax,
        restaurant_id: products[0].restaurantID,
        order_total: order_sub_total,
        order_items: strdata,
        total: order_orverall_total,
      };
      
      console.log(
        JSON.stringify({
          discount: discount,
          tax: tax,
          restaurant_id: products[0].restaurantID,
          order_total: total,
          order_items: strdata,
          total: subTotal - discount + tax,
        })
      );

      await axios
        .post("https://admin.order2go.us:3001/v1/orders/create", object, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("@access_token"),
          },
        })
        .then((res) => {
          console.log(JSON.stringify(res));
          if (res.data.status) {
            toast.success(res.data.message);
            localStorage.removeItem("@cartItem");
            history.push("/");
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.info(err.message);
        });
    } else {
      toast.error("INVALID CARD NUMBER PLEASE TRY VAILD NUMBER");
    }
  };

  return (
    <div>
      <main style={{ transform: "none", marginbottom: "30%" }}>
        <div className="container margin_detail" style={{ transform: "none" }}>
          <div className="row" style={{ transform: "none" }}>
            <div style={{ width: "800px" }} className="card">
              <div className="card-header">Payment Method</div>
              <div className="card-body">
                <div style={{ marginLeft: "34px", marginTop: "1%" }}>
                  {cardData.map((item, index) => (
                    <div id="listcartpayment" style={{ marginLeft: "22%" }}>
                      <div style={{ display: "flex" }}>
                        <div>
                          <input
                            type="radio"
                            style={{
                              width: "15px",
                              height: "15px",
                              cursor: "pointer",
                            }}
                            checked={individual === index}
                            onClick={() => {
                              setindividual(index);
                              setNumber(item.number);
                              SetExpiry(item.Year);
                              setCondition(false);
                              setShow(false);
                            }}
                            name="cart"
                          />

                          <div
                            className="mr-1 fs-unmask"
                            style={{ marginLeft: "40%", marginTop: "-35%" }}
                          >
                            <CardDisplay
                              number={item.number}
                              expand={true}
                              square={true}
                            />
                          </div>
                        </div>

                        <div style={{ marginLeft: "8%" }}>
                          <ul>
                            <li>
                              <label>
                                XXX XXX XXX
                                {item.number
                                  ? item.number.substr(item.number.length - 3)
                                  : ""}
                              </label>
                            </li>
                            <li>{item.Name}</li>
                          </ul>
                        </div>
                      </div>
                      <div
                        style={{
                          display: individual === index ? "block" : "none",
                        }}
                      >
                        <div className="ff-form-field">
                          <div className="ff-split">
                            <div
                              style={{ borderColor: "#bdbdbd" }}
                              className="ff-field ff-focused ff-split-left"
                            >
                              <label
                                className="ff-label"
                                for="cvv"
                                id="label-cvv"
                              >
                                {" "}
                                Cvv Code
                              </label>
                              <input
                                autocomplete="off"
                                type="password"
                                aria-label="Security Code"
                                size="40"
                                maxlength="3"
                                aria-labelledby="label-cvv"
                                className="ff-input"
                                required=""
                                id="cvv"
                                placeholder="•••"
                                data-payment="cc-cvc"
                                onChange={(e) => {
                                  if (e.target.value.length < 4) {
                                    SetCvv(e.target.value);
                                  }
                                }}
                              />
                            </div>
                            <div className="ff-field ff-split-right">
                              <div className="ff-center">
                                <img
                                  alt="guide_code"
                                  src="https://img6.wsimg.com/fos/react/icons/115/gd/sprite.svg#guide_code"
                                  style={{
                                    cursor: "default",
                                    width: "2.25rem",
                                    display: "inline",
                                    right: "10px",
                                    Top: "34px",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <br />

                <div id="Addcartpayment">
                  <div
                    className="card-header"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      backgroundColor: "#eeeeee",
                      marginBottom: "2%",
                      cursor: "pointer",
                      borderRadius: "12px",
                      fontFamily: "Georgia",
                      color: "#ffffff",
                    }}
                    onClick={() => {
                      setShow((s) => !s);
                      setindividual("");
                      setNumber("");
                      SetExpiry("");
                    }}
                  >
                    <h6 style={{ marginTop: "2%" }}>Debit Card</h6>
                    <button>
                      {show ? (
                        <AiOutlineMinus
                          onClick={() => {
                            setShow((s) => !s);
                            setindividual("");
                            setNumber("");
                            SetExpiry("");
                          }}
                          style={{ fontSize: "18px", fontweight: "900" }}
                        />
                      ) : (
                        <AiOutlinePlus
                          onClick={() => {
                            setShow((s) => !s);
                            setindividual("");
                            setNumber("");
                            SetExpiry("");
                          }}
                          style={{ fontSize: "18px", fontweight: "900" }}
                        />
                      )}
                    </button>
                  </div>

                  <div style={{ display: show ? "block" : "none" }}>
                    <div id="app">
                      <div>
                        <div className="d-flex flex-column payment-body">
                          <div className="ff-form-field">
                            <div className="ff-split">
                              <div className="ff-field ff-split-left">
                                <div className="ff-center">
                                  <div
                                    style={{
                                      display: "flex",
                                      paddingRight: "3%",
                                    }}
                                  >
                                    <CardDisplay
                                      number={number}
                                      expand={true}
                                      square={true}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div style={{ flexGrow: "1" }}>
                                <div
                                  style={{ borderColor: "#bdbdbd" }}
                                  className="ff-field ff-focused ff-split-right"
                                >
                                  <label
                                    className="ff-label"
                                    for="credit-card-number"
                                    id="label-credit-card-number"
                                  >
                                    Card Number
                                  </label>

                                  <input
                                    type="number"
                                    className="ff-input"
                                    maxlength="16"
                                    id="credit-card-number"
                                    autocomplete="cc-number"
                                    value={number}
                                    placeholder="•••• •••• •••• ••••"
                                    data-payment="cc-number"
                                    required
                                    onChange={(e) => {
                                      if (e.target.value.length <= 16) {
                                        setNumber(e.target.value);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="expiration-anv-cvv-wrapper">
                            <div className="ff-form-field">
                              <div className="ff-split">
                                <div style={{ flexGrow: "1" }}>
                                  <label className="titledesc" for="month">Expiration Date</label>
                                  <div className="row">
                                    <div
                                      id="paymentconfirmmonth"
                                      className="col-sm-4"
                                    >
                                      <select
                                        id="slectoption"
                                        className="form-control"
                                        name="expiry"
                                        style={{ textAlign: "center" }}
                                        onChange={handleDate}
                                      >
                                        <option value=" ">month</option>
                                        <option value="01">Jan</option>
                                        <option value="02">Feb</option>
                                        <option value="03">Mar</option>
                                        <option value="04">April</option>
                                        <option value="05">May</option>
                                        <option value="06">June</option>
                                        <option value="07">July</option>
                                        <option value="08">Aug</option>
                                        <option value="09">Sep</option>
                                        <option value="10">Oct</option>
                                        <option value="11">Nov</option>
                                        <option value="12">Dec</option>
                                      </select>
                                    </div>
                                    &nbsp;
                                    <div
                                    id="yeardesc"
                                    className="col-sm-4">
                                      <select
                                        className="form-control"
                                        id="slectoption"
                                        name="expiry"
                                        style={{ textAlign: "center" }}
                                        onChange={handleExpiry}
                                      >
                                        <option value=" ">Year</option>
                                        <option value="21">2021</option>
                                        <option value="22">2022</option>
                                        <option value="23">2023</option>
                                        <option value="24">2024</option>
                                        <option value="25">2025</option>
                                        <option value="26">2026</option>
                                        <option value="27">2027</option>
                                        <option value="28">2028</option>
                                        <option value="29">2029</option>
                                        <option value="30">2030</option>
                                        <option value="31">2031</option>
                                        <option value="32">2032</option>
                                        <option value="33">2033</option>
                                        <option value="34">2034</option>
                                        <option value="35">2035</option>
                                        <option value="36">2036</option>
                                        <option value="37">2037</option>
                                        <option value="38">2038</option>
                                        <option value="39">2039</option>
                                        <option value="40">2040</option>
                                        <option value="41">2041</option>
                                        <option value="42">2042</option>
                                        <option value="43">2043</option>
                                        <option value="44">2044</option>
                                        <option value="45">2045</option>
                                        <option value="46">2046</option>
                                        <option value="47">2047</option>
                                        <option value="48">2048</option>
                                        <option value="49">2049</option>
                                        <option value="50">2050</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="ff-form-field"
                                  style={{ marginBottom: "0px" }}
                                >
                                  <div className="ff-split">
                                    <div
                                      style={{ borderColor: "#bdbdbd" }}
                                      className="ff-field ff-focused ff-split-left"
                                    >
                                      <label
                                      
                                        className="ff-label"
                                        for="cvv"
                                        id="label-cvv"
                                      >
                                        Security Code
                                      </label>

                                      <input
                                        autocomplete="off"
                                        type="password"
                                        aria-label="Security Code"
                                        maxlength="3"
                                        error=""
                                        message=""
                                        aria-labelledby="label-cvv"
                                        className="ff-input"
                                        id="cvv"
                                        placeholder="•••"
                                        data-payment="cc-cvc"
                                        onChange={(e) => {
                                          if (e.target.value) {
                                            SetCvv(e.target.value);
                                          }
                                        }}
                                        required
                                      />
                                    </div>
                                    <div className="ff-field ff-split-right">
                                      <div className="ff-center">
                                        <img
                                          alt="guide_code"
                                          src="https://img6.wsimg.com/fos/react/icons/115/gd/sprite.svg#guide_code"
                                          style={{
                                            cursor: "default",
                                            width: "2.25rem",
                                            display: "inline",
                                            right: "10px",
                                            Top: "34px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <label
                      className="container_check"
                      style={{ display: "flex" }}
                    >
                      <button
                        style={{
                          borderRadius: "8px",
                          fontFamily: "Georgia",
                          color: "#ffffff",
                          fontSize: "10px",
                          padding: "2px",
                          width: Condition ? "40px" : "50px",
                          height: "20px",
                          textDecoration: "none",
                          backgroundColor: Condition ? "green" : "red",
                        }}
                        onClick={() => {
                          if (Condition === false) {
                            setCondition(true);
                          } else {
                            setCondition(false);
                          }
                        }}
                      >
                        {Condition ? "Added" : "Add Card"}
                      </button>
                      <p style={{ paddingLeft: "1%" }}>
                        {Condition
                          ? "Your Card will be saved at the time of Place order"
                          : "Click Add button to save details on Card"}
                      </p>
                    </label>
                  </div>
                </div>

                <button
                  className="btn btn btn-danger"
                  onClick={() => {
                    Authsubmit();
                  }}
                  style={{
                    textAlign: "center",
                    width: "100%",
                    backgroundColor: "rgb(236, 52, 89)",
                    color: "#fff",
                  }}
                >
                  Place order
                </button>
              </div>
            </div>

            <OrderCart Cartpropsdata={Cartpropsdata} />
          </div>
        </div>
      </main>
    </div>
  );
};

export default PaymentConfirm;
