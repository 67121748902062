import React, { useState } from 'react';
import axios from 'axios'
import { Card, CardText, CardBody, CardTitle, CardSubtitle, Cardbutton, CardImg } from 'reactstrap';
import { useHistory } from "react-router-dom";
import './log.css';
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

function Update() {


  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordHide, setPasswordHide] = useState(false);

  const togglePasswordVisiblity = (e) => {
    setPasswordShown(passwordShown ? false : true);
  };

  const passwordshowicon = <AiFillEye />;
  const passwordhideicon = <AiFillEyeInvisible />;








  const history = useHistory([]);
  const [password, setpassword] = useState();
  const [confirmpassword, setconfirmpassword] = useState();
  const formsend = () => {
  
    if(password != confirmpassword){
      alert("please Enter some Password")
      return;
    }
    axios
    .post(
        "https://api.order2go.us:3001/v1/users/updatePassword",
        {
          password: password,
          user_id:history.location.state.user_id,
        },
        {
          headers: {
            "Content-Type":"application/json",
            Accept: "application/json",
            Authorization: "Bearer " + (localStorage.getItem("@access_token")),
          },
        }
      )
      .then((response) => {
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);
      
        if (parsedObj.status === true) {
          alert(" updated succesfully")
          history.push("/Login");
        } else {
          alert("update Success");
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  
  return (
    <div>
      <div>
        <div
          style={{
            backgroundImage: "url(https://images.pexels.com/photos/4439740/pexels-photo-4439740.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center ,center",
            height: "657px"
          }}
          >
           <Card className='updatecard'>
            <CardBody>
              <div>
                <div >
                  <CardTitle className='updatetitle'>Set Password</CardTitle>
                  <CardText className='updatepara'>  You <span style={{ color: "rgb(236, 52, 89)", fontSize: "17px", fontWeight: "600" }}> new password</span> Must Same password and Confirm password.</CardText>
                  <div className='forgetcolumn'>
                    <div className="input-group mb-2">
                      <div >
                        <span className="input-group-text"></span>
                      </div>
                      <input 
      type={passwordShown ? "text" : "password"}
                      
                   
                       style={{ fontFamily: "verdana", }} onChange={(e) => { setpassword(e.target.value) }} className="form-control" placeholder="Password" />
                             <i
                        style={{ cursor: "pointer", fontSize: "20px" }}
                        onClick={(e) => togglePasswordVisiblity()}
                      >
                        {passwordshowicon}
                      </i>
                    </div>
                    <div >
                      <span className="input-group-text"></span>
                    </div>
                    <div className="input-group mb-2">
                      <div >
                        <span className="input-group-text"></span>
                      </div>
                      <input 
      type={passwordShown ? "text" : "password"}
                      
                   
                       style={{ fontFamily: "verdana", }} onChange={(e) => { setconfirmpassword(e.target.value) }} className="form-control" placeholder="confirmPassword" />
                             <i
                        style={{ cursor: "pointer", fontSize: "20px" }}
                        onClick={(e) => togglePasswordVisiblity()}
                      >
                        {passwordshowicon}
                      </i>
                    </div>
                    <div >
                      <span className="input-group-text"></span>
                    </div>
            
                    

            





                    <div >
                      <button onClick={(e) => formsend()} className="updatebut2"> Update Password</button>
                    </div>
            </div>
                </div>
              </div>
            </CardBody>
           </Card>
        </div>
      </div>
    </div>
  )
}

export default Update;
