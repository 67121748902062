import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Card, CardText, CardBody, CardTitle } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

import "./log.css";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../UserContext";

function Log() {
  const [email, setemail] = useState();
  const [password, setpassword] = useState();
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordHide, setPasswordHide] = useState(false);

  const togglePasswordVisiblity = (e) => {
    setPasswordShown(passwordShown ? false : true);
  };

  const passwordshowicon = <AiFillEye />;
  const passwordhideicon = <AiFillEyeInvisible />;

  useEffect(() => {
    if (typeof user === "object" && !Array.isArray(user) && user !== null) {
      history.push("/");
    }
  }, []);

  const storeData = async (value) => {
    try {
      await localStorage.setItem("@access_token", value);
    } catch (e) {
      alert(e.message, "AccessToken Error");
    }
  };

  const formsend = () => {

    
    if(!password || !email){
      alert("Please Enter email password Required Fields")
      return
    }

    const data = {
      email: email,
      password: password,
    };
  
    axios
      .post("https://api.order2go.us:3001/v1/users/login", data)
      .then((response) => {
        var stringified = JSON.stringify(response);
        var parsedObj = JSON.parse(stringified);
    
if(parsedObj.data.status == true){
          storeData(response.data.token);
          localStorage.setItem(
            "@user_data",
            JSON.stringify(response.data.user)
          );
          setUser(JSON.parse(JSON.stringify(response.data.user)));
          toast.success("Login Successfully");
          
          history.push(`/`);
          window.location.reload();

}else{
toast.info(parsedObj.data.message);
}


      })
      .catch((err) => {
        alert(err.message);
      });
  };
  return (
    <div>
      <div>
        <div>
          <ToastContainer />
          <div
            style={{
              backgroundImage:
                "url(https://images.pexels.com/photos/718742/pexels-photo-718742.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center ,center",
              height: "657px",
            }}
          >
            <Card className="logincard">
              <CardBody>
                <div>
                  <div>
                    <CardTitle className="logintitle">Order2go</CardTitle>
                    <CardText className="loginpara">
                      {" "}
                      Login to your Account{" "}
                    </CardText>
                    <div className="input-group mb-2">
                      <div>
                        <span className="input-group-text"></span>
                      </div>
                      <input
                        type="text"
                        onChange={(e) => {
                          setemail(e.target.value);
                        }}
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>

                    <span className="input-group-text">
                      {" "}
                      <input
                        type={passwordShown ? "text" : "password"}
                        onChange={(e) => {
                          setpassword(e.target.value);
                        }}
                        className="form-control"
                        placeholder="Password"
                      />
                      <i
                        style={{ cursor: "pointer", fontSize: "20px" }}
                        onClick={(e) => togglePasswordVisiblity()}
                      >
                        {passwordshowicon}
                      </i>
                    </span>
                    <div>
                      <button
                        onClick={(e) => formsend()}
                        className=" loginbut2"
                      >
                        login
                      </button>
                    </div>
                    <div className="loginforget">
                      <a href="/Forget">Forgot your password?</a>
                    </div>
                  </div>
                </div>
              </CardBody>
              <CardTitle>
                <a href="/Register" className="logincreate">
                  {" "}
                  Create New Account{" "}
                </a>{" "}
              </CardTitle>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Log;
