

import React, { useState,useContext } from 'react'
import "./style.css"
import Cart from './cart.gif'
import {toast,ToastContainer} from 'react-toastify'
import { UserContext } from "../../UserContext";


const Card = () => {
  const PRODUCTS = JSON.parse(localStorage.getItem("@cartItem")) == null ? [] : JSON.parse(localStorage.getItem("@cartItem"));
  const TAX = 5;
  const [count, setCount] = useState(1)
  const CLONE_PRODUCTS = JSON.parse(JSON.stringify(PRODUCTS));
  const [products, setProducts] = useState(CLONE_PRODUCTS);
  const [discountPercent, setDiscountPercent] = useState(0);
  const {Cartsize, setCartsize} =useContext(UserContext);

  const subTotal = products.reduce((total, product) => {
    return total + product.price * +product.qty;
  }, 0);


  const discount = (subTotal * discountPercent) / 100;



  function formatCurrency(value) {
    return Number(value).toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
  }

  const onChangeProductQuantity = (index, event, value1) => {

    const value = event.target.value;
    const valueInt = parseInt(value);
    const cloneProducts = [...products];

    if (value1 == "+") {

      cloneProducts[index].qty = products[index].qty + 1;

    } else if (value1 == "-") {

      cloneProducts[index].qty =
        products[index].qty == 1
          ? onRemoveProduct(index)
          : products[index].qty - 1;
    }
    console.log(cloneProducts[index].qty);
    setProducts(cloneProducts);

  };

  const onRemoveProduct = (i) => {

    const filteredProduct = products.filter((product, index) => {
      return index != i;
    });
    localStorage.setItem("@cartItem", JSON.stringify(filteredProduct));
    toast.error("cart Removed Successfully");
    var i = 0;
    var test =  setInterval(() => {
      i++;
      setProducts(filteredProduct);
      setCartsize(filteredProduct.length)
      if (i < 3) {
        clearInterval(test);
    }
    }, 1000);
  };

  function ProductList({ products, onChangeProductQuantity, onRemoveProduct }) {
    return (

      <div style={{ margin: "88px 50px" }}>
        <div className="divTable div-hover">
          <div className="rowTable bg-primary text-white pb-2">
            <div className="divTableCol">Product Image</div>
            <div className="divTableCol">Product</div>
          
            <div className="divTableCol">Quantity</div>
            <div className="divTableCol">Price</div>
            <div className="divTableCol">Total</div>
            <div className="divTableCol">Actions</div>
          </div>

          {products.map((product, index) => {
            return (

              <div className="rowTable">
                <div className="divTableCol">
                  <div className="media">
                    <a className="thumbnail pull-left mr-2" href="#">
                      <img className="media-object"

                        src={`https://api.order2go.us:3001/${product.picture}`}
                        style={{ width: "72px", height: "72px" }} />
                    </a>

                  </div>
                </div>
                <div className="divTableCol"><strong className="label label-warning">{product.name}</strong></div>
                <div className="divTableCol">

                  <div className="quantity" style={{ display: "flex" }}>

                    <div style={{ padding: "0% 4%" }}>{product.qty}</div>
                    <div className="inc qty-button" onClick={(event) => onChangeProductQuantity(index, event, "+")}>+</div>
                    <div onClick={(event) => onChangeProductQuantity(index, event, "-")} className="dec qty-button">-</div>
                  </div>

                </div>
                <div className="divTableCol"><strong>{formatCurrency(product.price)}</strong></div>
                <div className="divTableCol"><strong>{formatCurrency(product.price * product.qty)}</strong></div>
                <div className="divTableCol">
                  <button type="button" className="btn btn-danger" onClick={() => onRemoveProduct(index)}><span className="fa fa-remove"></span> Remove</button>
                </div>
              </div>

            )
          })}
        </div>
      </div>

    );
  }



  function Summary({
    subTotal,
    discount,
    tax
  }) {
    const total = subTotal - discount + tax;

    return (


    <section className="container_card">
      <ToastContainer />
        <div className="summary" style={{ textAlign: "center" }}>
          <ul>
            <li>
              Subtotal <span>{formatCurrency(subTotal)}</span>
            </li>
            {discount > 0 && (
              <li>
                Discount <span>{formatCurrency(discount)}</span>
              </li>
            )}
            <li>
              Tax <span>{formatCurrency(tax)}</span>
            </li>
            <li className="total">
              Total <span>{formatCurrency(total)}</span>
            </li>
          </ul>
        </div>
        <div className="checkout" style={{ textAlign: "center" }} >
          <button type="button" className='btn btn-success'>Check Out</button>
        </div>
    </section>


    );
  }





return (


    <div>
      {products.length > 0 ? (
        <div>
          <ProductList
            products={products}
            onChangeProductQuantity={onChangeProductQuantity}
            onRemoveProduct={onRemoveProduct}
          />

          <Summary
            subTotal={subTotal}
            discount={discount}
            tax={TAX}
          />
        </div>
      ) : (
        <div
          style={{
            marginTop: '13px',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundColor: "#0A090D",
            width: '100vw',
            height: '100vh'
          }}

        >


<div style={{textAlign:"center",padding:"14%"}}>

          <img src={Cart} alt="No cart" 
          style={{
            
            borderRadius: "1000%",
          }} />

          <h2 style={{color:"white" ,paddingTop:"5%"}}>Your cart is Empty Add Your Favourite !</h2>

          </div>
        </div>

      )}
    </div>


)

}

export default Card;