import React, { useState, useContext } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Cardbutton,
  CardImg,
} from "reactstrap";
import "./log.css";
import { UserContext } from "../../UserContext";
import { useHistory } from "react-router-dom";

function Otp() {
  const history = useHistory();
  const [num, setNum] = useState();
  const [code, setCode] = useState("");

  const formsend = () => {
    axios
      .post(
        "https://api.order2go.us:3001/v1/users/verfifyOtp",
        {
          otp: num,
          user_id: history.location.state.user_id,
        },


        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("@access_token"),
          },
        }
      )
      .then((response) => {
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);
        console.log(stringified);
        if (parsedObj.status === true) {
          alert(parsedObj.message);
          history.push({
            pathname: "/updatepassword",
            state: { user_id: history.location.state.user_id },
          });
        } else {
          alert(parsedObj.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <div>
      <div
        style={{
          backgroundImage:
            "url(https://images.pexels.com/photos/7758253/pexels-photo-7758253.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center ,center",

          height: "657px",
        }}
      >
        <Card className="OTPcard">
          <CardBody>
            <div>
              <div className="OTPtitle">
                <p>
                  {" "}
                  We have sent you{" "}
                  <span className="otpspan">OneTimePassword </span>your mobile{" "}
                  <span className="question"> ?</span>{" "}
                </p>
              </div>

              <div className="OTPintputarea">
                <label>
                  <b>
                    Please <span className="otpspan1">EnterOTP:</span>
                  </b>

                  {
                    <div
                      style={{ marginTop: "10%" }}
                      className="input-group mb-2"
                    >
                      <div>
                        <span className="input-group-text"></span>
                      </div>
                      <input
                        style={{ textAlign: "center" }}
                        type="number"
                        className="form-control"
                        placeholder=""
                        onChange={(e) => {
                          setNum(e.target.value);
                        }}
                        onkeypress="return this.value.length < 4;"
                        oninput="if(this.value.length>=4) { this.value = this.value.slice(0,4); }"
                        maxlength="4"
                      />
                    </div>
                  }
                </label>
              </div>

              <div className="OTPbut">
                <button onClick={() => formsend()} className="OTPbut2">

                  Verify OTP
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default Otp;
