import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const OrderCart = (props) => {
  const [totalAmount, setTotalAmount] = useState();
  const [discount, setdiscount] = useState();
  const [tax, settax] = useState();

  const histroy = useHistory();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("@user_data")) === null) {
      histroy.push("/login");
    }
    setoffer()
  }, []);

  const setoffer = () => {
    var isMin =
      props.Cartpropsdata.subTotal >=
      props.Cartpropsdata.products[0]?.min_order_amount;
    setTotalAmount(
      isMin
        ? props.Cartpropsdata.formatCurrency(
            props.Cartpropsdata.subTotal -
              (props.Cartpropsdata.subTotal *
                props.Cartpropsdata.products[0].discount) /
                100 +
              (props.Cartpropsdata.subTotal *
                props.Cartpropsdata.products[0].tax) /
                100
          )
        : props.Cartpropsdata.formatCurrency(props.Cartpropsdata.subTotal)
    );
    setdiscount(
      isMin
        ? (props.Cartpropsdata.subTotal *
            props.Cartpropsdata.products[0]?.discount) /
            100
        : 0
    );
    settax(
      isMin
        ? (props.Cartpropsdata.subTotal *
            props.Cartpropsdata.products[0]?.tax) /
            100
        : 0
    );

    // if (
    //   props.Cartpropsdata.subTotal >= props.Cartpropsdata.products[0]?.min_order_amount
    // ) {
    //   setcondition(true);
    // }
  };
  return (
    <div
      className="col-lg-4"
      id="sidebar_fixed"
      style={{
        position: "relative",
        overflow: "visible",
        boxSizing: "border-box",
        marginTop: "4%",
        minHeight: "1px",
      }}
    >
      <ToastContainer />
      <div
        className="theiaStickySidebar"
        style={{
          paddingTop: "0px",
          paddingBottom: "1px",
          position: "static",
          transform: "none",
          top: "0px",
        }}
      >
        <div className="box_booking" style={{ position: "sticky" }}>
          <div
            className="head"
            style={{ textAlign: "center", position: "sticky" }}
          >
            <h3 style={{ textAlign: "center" }}>Cart List</h3>
          </div>
          <div>
            <div>
              {props.Cartpropsdata.products.map((product, index) => {
                return (
                  <div className="_1t-Al _1XUXj">
                    <div className="_3YMqW"></div>
                    <div className="_2ObNr _2Y5ZT _2qOpI">
                      <div>
                        <div className="_2zsON"></div>
                        <div className="MGAj1">
                          <div className="_2bXOy">
                            <div className="_3SG03">
                              <div className="_33KRy">
                                <img
                                  src={`https://api.order2go.us:3001/${
                                    product.picture == null
                                      ? "uploads/images/Image-1637340817630.jpg"
                                      : product.picture
                                  }`}
                                  width="40px"
                                  alt=""
                                />{" "}
                                {product.name}
                              </div>
                            </div>
                            <div className="_2bWmk">
                              <div className="_1yTZI">
                                <div className="_29ugw _3L1X9">
                                  <div className="_1RPOp _36fT9 _4aKW6">
                                    ADD
                                  </div>
                                  <div className="_3Hy2E hDN3x _4aKW6">-</div>

                                  <div
                                    className="_1ds9T"
                                    onClick={(event) =>
                                      props.Cartpropsdata.onChangeProductQuantity(
                                        index,
                                        event
                                      )
                                    }
                                  ></div>
                                  <div
                                    // className="_29Y5Z"
                                    onClick={(event) =>
                                      props.Cartpropsdata.onChangeProductQuantity(
                                        index,
                                        event
                                        // "-"
                                      )
                                    }
                                  ></div>

                                  <div className="_2zAXs">{product.qty}</div>
                                </div>
                                <div
                                  className="_1mx0r"
                                  style={{ marginRight: "20px" }}
                                >
                                  <span className="_2W2U4">
                                    $ {product.price}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="_3DPdG"></div>
                      </div>
                    </div>
                    <div className="_1v28S"></div>
                  </div>
                );
              })}

              <div className="EEeV3">
                <div className="_161V3">
                  <div className="_1DWmI" style={{ marginLeft: "10px" }}>
                    Subtotal
                  </div>
                  <div style={{ marginRight: "20px" }}>
                    <span>$ {props.Cartpropsdata.total}</span>
                  </div>
                </div>
                <div className="_26NCu" style={{ marginLeft: "10px" }}>
                  Extra charges may apply
                </div>
                <div className="_161V3">
                  <div
                    className="_1DWmI"
                    style={{ marginLeft: "10px", fontSize: "12px" }}
                  >
                    Discount
                  </div>
                  <div style={{ marginRight: "20px", fontSize: "12px" }}>
                    {/* <span>$ {props.Cartpropsdata.discount}</span> */}
                    <span>$ {discount}</span>
                  </div>
                </div>
                <div className="_161V3">
                  <div
                    className="_1DWmI"
                    style={{ marginLeft: "10px", fontSize: "12px" }}
                  >
                    Tax
                  </div>
                  <div style={{ marginRight: "20px", fontSize: "12px" }}>
                    {/* <span>$ {props.Cartpropsdata.tax}</span> */}
                    <span>$ {tax}</span>
                  </div>
                </div>
                <div className="_161V3" style={{ marginTop: "5%" }}>
                  <div
                    className="_1DWmI"
                    style={{ marginLeft: "10px", fontSize: "20px" }}
                  >
                    Total
                  </div>
                  <div style={{ marginRight: "20px", fontSize: "20px" }}>
                    {/* <span>
                      {props.Cartpropsdata.formatCurrency(
                        props.Cartpropsdata.subTotal -
                          props.Cartpropsdata.discount +
                          props.Cartpropsdata.tax
                      )}
                    </span> */}
                    <span>
                      {totalAmount}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCart;
