import React, { useState, useEffect,useContext } from "react";
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import { toast, ToastContainer } from "react-toastify";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { BiUpArrowAlt } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import Sidebar from "../Sidebar";
import './style/myorderlist.css';

import { UserContext } from "../../../UserContext";
import Popup from "reactjs-popup";

var userdata =
  JSON.parse( localStorage.getItem("@user_data")) == null
    ? []
    : JSON.parse( localStorage.getItem("@user_data"));


function onFormSubmit() {
  }
const dateCallback = (selectedDate) => {
  const currentDate = selectedDate || Date;
  const dateis = "" + currentDate;
  const arr1 = dateis.split("T");
  const arr2 = arr1[0];
  const time = arr1[1]
  const arr3 = time.split(":");
  var arr4 = arr3[0];
  const arr5 = arr3[1];
  const arr6 = arr4 > 12 ? "PM" : "AM";
  arr4 = arr4 > 12 ? arr4 - 12 : arr4;
  const myDate = " " + arr2 + " " + arr4 + ":" + arr5 + "" + arr6 + " " + new Date(currentDate).toLocaleTimeString()
  return new Date(currentDate).toDateString()
};

const Myorderlist = (props) => {

const [name, setname] = useState(0);
const { setCartsize} =useContext(UserContext);
const [ResetCart, setResetCart] = useState(false);
const [Restaurantid, setRestaurantid] = useState();
const [time, setTime] = useState(false);
const [time1, setTime1] = useState(false);
const [order, setOrders] = useState([]);
const [user, setuser] = useState("");
const history = useHistory(0);
const fulldate = new Date();
// let currentday = fulldate.getDay();
const labels = ["Queue", "Confirmed", "In Kitchen", "Ready", "Delivered"];







  useEffect(() => {

    setuser(
      JSON.parse(localStorage.getItem("@user_data")) === null ? false : true
    );

    getDatas();
  
    getOrderData();
    
  }, []);


  var date1 = new Date("02/19/2022"); //Tue Feb 22 2022
  var date2 = new Date();

  var Difference_In_Time =
    new Date().getTime() - new Date("02/19/2022").getTime();
  var Difference_In_Days =
    (new Date().getTime() - new Date("02/19/2022").getTime()) /
    (1000 * 3600 * 24) <
    3;




  
const getOrderData = async () => {
  axios
    .get("https://api.order2go.us:3001/v1/orders/findOrder", {
      headers: {
        "Content-Type": "application/json",
        from_mobile: "true",
        restaurant_id: 21,
        Authorization:
          "Bearer " + (await  localStorage.getItem("@access_token")),
      },
    })
    .then((response) => {
      var stringified = JSON.stringify(response.data);
      var parsedObj = JSON.parse(stringified);
      if (parsedObj.status == true) {
        setOrders(parsedObj.data);
        // console.log(parsedObj.data, "Order Object");
      } else {
        alert("Failed To  add my order");
      }
    });
};


// ----------------------------

const getDatas = async () => {
  var values = await localStorage.getItem("@cartItem");
  if (values === null) {
    setCartData([]);
  } else {
    var data = [];
    data.push(values);
    const mydata = JSON.parse(data);
    setCartData(mydata);
  }
};

const PRODUCTS = JSON.parse(localStorage.getItem("@cartItem")) == null
      ? []
      : JSON.parse(localStorage.getItem("@cartItem"));
const [count, setCount] = useState(1);
const CLONE_PRODUCTS = JSON.parse(JSON.stringify(PRODUCTS));
const [products, setProducts] = useState(CLONE_PRODUCTS);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [cartData, setCartData] = useState([]);
  let currentday = fulldate.getDay();
  const [taxdetail, setTax] = useState(0);

 

  const CheckAlreadyCart = (props) => {

    // setCartsize(products.length

    setResetCart({
      categoryID: props.order_items[0].menu.category_id,
      description: props.order_items[0].menu.desc,
      discount:props.discount,
      id: props.id,
      menuID: props.order_items[0].menu.id,
      name: props.order_items[0].menu.name,
      picture: props.order_items[0].menu.picture,
      price: props.order_items[0].price,
      qty: 1,
      tax: props.restaurant.tax,
      restaurantID: props.restaurant.id,
      subCategoryID: props.order_items[0].menu.sub_category_id,
      min_order_amount:0,
    });

    const idToRemove = props.id;
    const idToRemove1 = props.restaurant.id;

    if (cartData.length === 0) {
      storeCartData(props);
    } else {
      var item = products.find((item) => item.categoryID === props.order_items[0].menu.category_id);
      var item1 = products.find((item) => item.restaurantID != idToRemove1);
      var i = 0;

      if (item) {
        toast.info("ITEM ALREADTY ADDED TO CART");
      } else if (item1) {
        toggleModal();
      } else {
        storeCartData(props);
        toast.success("ITEM ADDED TO CART Successfully");
      }
    }

  };

  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const Change = () => {
    return (
      <Popup
        open={isOpen}
        className="popup-modal-container-box"
        modal
        // lockScroll={true}
        closeOnDocumentClick={false}
      >
        <div class="_2835q">
          <div class="tdLYq">
            <div class="zmgWM">
              <div class="R4-yb">Items already in cart</div>
              <div class="VzKYj">
                <div class="_3XWJf">
                  Your cart contains items from other restaurant. Would you like
                  to reset your cart for adding items from this restaurant?
                </div>
              </div>
            </div>
          </div>
          <div class="_3G9po">
            <button class="b0G1m" onClick={toggleModal}>
              No
            </button>
            <span class="U5Ri6"></span>
            <button class="_2-MHS" onClick={() => storeCartData()}>
              Yes, start afresh
            </button>
          </div>
        </div>
      </Popup>
    );
  };

  const storeCartData = async (props) => {
 
    console.log(props)
    if (props === undefined) {
      setIsOpen(!isOpen);
      localStorage.removeItem("@cartItem");
      setProducts([]);
      var newCartItem = ResetCart;
    } else {
      var newCartItem = ResetCart;
    }

    let existingTransactions = await getCartData();
    if (existingTransactions.length === 0) {
      var newarr = existingTransactions;
    } else {
      var newarr = JSON.parse(existingTransactions);
    }
    const updatedTransactions = [...newarr, newCartItem];
    await localStorage.setItem(
      "@cartItem",
      JSON.stringify(updatedTransactions)
    );
    alert("Cart Added Successfully")
    setProducts(updatedTransactions);
    setCartsize(updatedTransactions.length)
    // window.location.reload();
  };

  const getCartData = () => {
    let transactions = localStorage.getItem("@cartItem");
    if (transactions) {
      return transactions;
    } else {
      return new Array();
    }
  };

  const cancelOrder = async (item) => {

    // console.log("status ", item.status);
    // console.log("order_id ", item.order_id);
    // console.log("retref ", item.retref);
    axios
      .post(
        "https://api.order2go.us:3001/v1/payment/void",
        {
          // order_status: item.status,
          order_id: item.order_id,
          // retref: item.retref,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer " + (await localStorage.getItem("@access_token")),
          },
        }
      )
      .then((response) => {
        
        console.log(response.data);
        var stringified = JSON.stringify(response);
        var parsedObj = JSON.parse(stringified);
        if (response.data.status == true) {
          console.log("Order Canceled Sucess");
         
        } else if (response.data.status == false) {
          console.log("Order Canceled failure");
         
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

return (
    <div class="wrapper d-flex align-items-stretch">
      <Sidebar Color={2} />
      {Change()}
      <div id="content" class="p-4">
        <ToastContainer />
        {order.length>0?(
   <div>
   {order.map((data, index) => (
     <Card id="oltotalcard" style={{ display: "flex", marginTop: "30px", height: "250px", width: "90%", marginLeft: "5%" }} className="text-center">
       <Card.Header id="olcardheader" style={{ backgroundColor: "#eeeeee",  }}>
         <ul id="olcardheaderul" style={{ display: "flex", justifyContent: "space-between",  height: "5px" }}>
           <li  classname="headertext"  style={{  fontWeight: "600" }}>
             OrderID:<span>{data.order_id}</span>
           </li>
           <li  classname="headertext"   style={{fontWeight: "600" , }}>
             {data.restaurant_name}
           </li>
           <li  classname="headertext"   style={{ fontWeight: "600",  }}>
             Over all:<span>{data.order_total} $</span>
           </li>
           <li  classname="headertext" style={{ fontWeight: "600",  }} >
             Date:<span>{dateCallback(data.updated_at)}</span>
           </li>
         </ul>
       </Card.Header>
       <Card.Body >

         <Card.Title id="olcardtitle"  >
           <ul id="olcardtitleul" style={{ display: "flex", justifyContent: "space-around", fontSize: "1vw", width: "400px" }}>
             <li>No</li>
             <li>Name</li>
             <li>Qty</li>
             <li>Price</li>
           </ul>
           {data.order_items.map((item, number) => {
             return (

               <ul id="olcardtitleul2" style={{ display: "flex", justifyContent: "space-around", fontSize: "1vw", width: "400px" }} >
                 <li>{number + 1}</li>
                 <li >{item.menu.name.substring(0, 23)}</li>
                 <li style={{ marginRight: "30px" }}> {item.qty}</li>
                 <li>{item.price + " $"}</li>
               </ul>

             )
           })}
         </Card.Title>

         <Card.Text id="olcardtext" style={{ height: "auto", width: "500px", float: "right",paddingLeft:"10%",paddingRight:"1%", position: "relative", bottom: "110px", fontSize: "1vw", }}>

           {data.status_text !== "Cancelled" ? (

             <StepProgressBar

               startingStep={data.status_text === "Queue" ? 0 : data.status_text === "Confirmed" ? 1 : data.status_text === "Preparing" ? 2 : data.status_text ==="On the way" ? 3 : data.status_text === "Delivered" ? 4 : 0}
               onSubmit={onFormSubmit}
               steps={[
                 {
                   label: 'Queue',
                   name: 'step 0',
                 },
                 {
                   label: 'Confirmed',
                   name: 'step 1',
                   // content: step2Content,
                   // validator: step2Validator
                 },
                 {
                   label: 'kithcen',
                   name: 'step 2',
                   // content: step3Content,
                   // validator: step3Validator
                 },
                 {
                   label: 'Ready',
                   name: 'step 3',
                   // content: step4Content,
                   // validator: step4Validator
                 },
                 {
                   label: 'Delivered',
                   name: 'step 4',
                   // content: step5Content,
                   // validator: step5Validator
                 }
               ]}
             />
           ) : (

             <h2 style={{ color:"red",fontSize: "22px", marginTop: "1%", justifyContent: "center", position: "relative", top:"50px"}}>{data.status_text}</h2>

           )}
           <div id="myollistbuttons"   style={{ height: "auto", display: "flex", justifyContent: "space-around", position: "relative", bottom: "50px", fontSize: "15px", left: "160px" }}>
             <Button id="myollistbtn"   onClick={() => CheckAlreadyCart(data)}   style={{ borderRadius: "30px", color: "white",marginLeft:"10%",backgroundColor: "#EC3459", height: "30px", marginTop: "5%", padding: "5px", textAlign: "center", fontSize: "12px", fontWeight: "700", display: data.status_text === "Delivered" ? "block" : "none" }} variant="outline -secondary ">ReOrder</Button>
             <Button   id="buttonviewall"   className="viewall" onClick={()=>cancelOrder(data)}  style={{ borderRadius: "10px", color: "white", backgroundColor: "#f01d1d", height: "30px", padding: "3px", textAlign: "center",  position:"relative", top:"20px", fontSize: "14px", fontWeight: "500", textTransform: "capitalize", display:  data.status_text === "Queue" ? "block" : "none" }} variant="outline -secondary " > cancel</Button>
            
             <Button  className="myolreviewbtn" style={{ borderRadius: "10px", color: "white", backgroundColor: "grey", height: "30px", padding: "3px", textAlign: "center",  position:"relative", top:"20px", fontSize: "14px", fontWeight: "500", textTransform: "capitalize", 
           display:
           (new Date().getTime() -
             new Date(data.updated_at).getTime()) /
             (1000 * 3600 * 24) <
             3  && data.status_text === "Delivered" ? "block" : "none" 
           
           
           }} variant="outline -secondary " > <a href="/review" style={{color:"#fff"}}>Review</a> </Button>

           </div>
         </Card.Text>
       </Card.Body>
     </Card>
         ))}
     </div>
        ):(
          <h4 style={{textAlign:"center",marginTop:"20%"}}>
            No Data Found
          </h4>
        )}
      </div>
 

    </div>
  );
};
export default Myorderlist;
