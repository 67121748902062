import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Cardbutton,
  CardImg,
} from "reactstrap";
import { AiFillEyeInvisible, AiFillEye, AiFillCaretDown } from "react-icons/ai";
import { useHistory } from "react-router-dom";

import "./log.css";
import { toast } from "react-toastify";
function Reg() {
  const history = useHistory([]);
  const [email, setemail] = useState();
  const [phone, setphone] = useState();
  const [Address, setAddress] = useState();
  const [State, setState] = useState();
  const [Zipcode, setZipcode] = useState();
  const [name, setname] = useState();
  const [statevalue, SetStatevalue] = useState([]);
  const [cityvalue, Setcityvalue] = useState([]);
  const [city, setCity] = useState([]);

  const icon = <AiFillCaretDown />;

  useEffect(() => {
    getaddress();
  }, []);

  const getaddress = () => {
    axios
      .get("https://api.order2go.us:3001/v1/location/getStates", {
        headers: {
          "Content-Type": "application/json",
          from_mobile: true,
        },
      })
      .then((response) => {
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);
        if (parsedObj.status == true) {
          SetStatevalue(response.data.data);
        } else if (parsedObj.status == false) {
          console.log(JSON.stringify(response.data.data));
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const getcity = (e) => {
    axios
      .get(`https://api.order2go.us:3001/v1/location/getCities?state_id=${e}`, {
        headers: {
          "Content-Type": "application/json",
          from_mobile: true,
        },
      })
      .then((response) => {
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);
        if (parsedObj.status == true) {
          Setcityvalue(response.data.data);
        } else if (parsedObj.status == false) {
          // console.log(JSON.stringify(response.data.data));
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const formsend1 = () => {
    if (!name || !email || !phone || !Address || !city || !State || !Zipcode) {
      alert("Please Enter All Required Fields")
      return
    }

    axios.post(

      "https://api.order2go.us:3001/v1/users/registerUser",
      {
        name: name,
        email: email,
        phone: phone,
        address: Address,
        city_id: city,
        state_id: State,
        zip: Zipcode,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          from_mobile: true,
          Authorization: "Bearer " + localStorage.getItem("@access_token"),
        },
      }
    ).then((response) => {
      var stringified = JSON.stringify(response.data);
      var parsedObj = JSON.parse(stringified);
      alert(JSON.stringify(response.data.message))
      if (parsedObj.status === true) {
        localStorage.setItem("otpuserid", parsedObj.user_id);
        alert(JSON.stringify(parsedObj));

        history.push({
          pathname: `/otp`,
          state: { user_id: parsedObj.user_id },
        });
      }
    })
      .catch((error) => {
        alert(error.message);
      });
  };

  const setinputvalue = (index) => {
    setCity(cityvalue[index].id)
    setZipcode(cityvalue[index].zip)
  }

  return (
    <div>
      <div>
        <div>
          <div
            style={{
              backgroundImage:
                "url(https://images.pexels.com/photos/958547/pexels-photo-958547.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center ,center",
              height: "757px",
            }}
          >
            <Card className="registercard">
              <CardBody>
                <div>
                  <CardText
                    style={{ marginTop: "-17px" }}
                    className="registerpara"
                  >
                    order2go
                  </CardText>
                  <div>
                    <div className="input-group mb-2">
                      <div>
                        <span className="input-group-text"></span>
                      </div>
                      <input
                        type="text"
                        onChange={(e) => {
                          setname(e.target.value);
                        }}
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                    <div>
                      <span className="input-group-text"></span>
                    </div>
                    <input
                      type="email"
                      onChange={(e) => {
                        setemail(e.target.value);
                      }}
                      className="form-control"
                      placeholder="Email"
                    />
                    <div>
                      <span className="input-group-text"></span>
                    </div>
                    <input
                      type="number"
                      onChange={(e) => {
                        if (e.target.value.length < 11) {
                          setphone(e.target.value);
                        }
                      }}
                      className="form-control"
                      value={phone}
                      placeholder="Phone"
                    />
                    <div>
                      <span className="input-group-text"></span>
                    </div>
                    <input
                      type="text"
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                      className="form-control"
                      placeholder="Address"
                    />

                    <div>
                      <span className="input-group-text"></span>
                    </div>
                    <div class="form-group add_bottom_15">
                      <label htmlFor="">State:</label>
                      <select
                        onChange={(e) => {
                          getcity(e.target.value);
                          setState(e.target.value)
                        }}
                        className="form-control"
                      >
                        <option value="">Select State</option>

                        {statevalue &&
                          statevalue.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))}
                      </select>
                    </div>
                    <div>
                      <div class="form-group add_bottom_15">
                        <label htmlFor=""> City:</label>
                        <select
                          onChange={(e) => {
                            setinputvalue(e.target.value)

                          }}
                          className="form-control"
                        >
                          <option value="">Select City</option>
                          {cityvalue &&
                            cityvalue.map((item, index) => (
                              <option value={index}>{item.name}</option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <input
                      type="text"
                      onChange={(e) => {
                        if (e.target.value.length < 6) {
                          setZipcode(e.target.value);
                        }
                      }}
                      value={Zipcode}
                      className="form-control"
                      placeholder="ZipCode"
                    />
                    <div>
                      <span className="input-group-text"></span>
                    </div>
                    <div>
                      <button
                        onClick={(e) => formsend1()}
                        style={{ marginTop: "10px" }}
                        className="registerbut2"
                      >
                        {" "}
                        Register
                      </button>
                    </div>
                    <CardTitle>
                      {" "}
                      <a href="/Login" className="registercreate">
                        {" "}
                        Already have an Account{" "}
                        <span
                          style={{
                            color: "rgb(236, 52, 89)",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          Login
                        </span>{" "}
                      </a>{" "}
                    </CardTitle>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Reg;
