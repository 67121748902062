import React, {useState} from 'react';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1,SearchTag,Input } from './HeroElements';

import Zoom from 'react-reveal/Zoom';

import "./style/style.css"


const HeroSection = props => {
    const [hover, setHover] = useState(false);
    const [value, setValue] = useState("");
    return (
        <HeroContainer id='home'>
            <HeroBg>
            <VideoBg />
            </HeroBg>
            <HeroContent>
            <Zoom duration={3000}>
                <HeroH1>Order2Go</HeroH1>
                </Zoom>

              <p style={{fontSize:"25px",color:"white",textAlign:"center"}}> The Best Restaurants At the Best Price</p>         
                       <div className="search">
                    <div>
                        <div className="search-2">
                            <input type="text" placeholder="Restaurant"  onChange ={(e)=>{setValue(e.target.value)}} /> 
                        <SearchTag className="btn" style={{textAlign:"center"}} onClick={() =>{value === ""?props.search(" "):props.search(value)}}>Search</SearchTag>  
                        </div>
                    </div>
                 </div>
            </HeroContent>
        </HeroContainer>
    )
}


export default HeroSection
