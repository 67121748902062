import axios from 'axios';
import { date } from 'language-tags';
import React,{useState,useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { toast,ToastContainer } from 'react-toastify';
import Resturent from '../Resturent';
import "./payment.css"

function formatDate(date) {
	return [
		date.getFullYear(),
		("0" + (date.getMonth() + 1)).slice(-2),
	  ("0" + date.getDate()).slice(-2),
	].join("-");
  }


function Booking() {
	const [Table,setTable]=useState(new Array);
	const [Tableid,setTableid]=useState("");
	const [showstatus,setShowstatus]=useState(false);
	const [display,setdisplay]=useState("block");
    const history = useHistory();

	const [restaurant, setRestaurant] = useState(
		history.location.state.booking
	  );
	  useEffect(()=>{
		setRestaurant(history.location.state.booking)
        setTable(restaurant.tabledata)
	  },[])

const submit = () =>{
function changformat(date){
	const arr = date.split("-")
 const merge= arr[2]+"-"+arr[1]+"-"+arr[0];
 return merge;
}

const date = changformat(restaurant.date);
const object = {
	"restaurant_id": restaurant.ResturentId,
	"date": date+"T"+restaurant.starttime+":31.587Z",
	"start_time": date+"T"+restaurant.starttime+":31.587Z",
	"end_time": date+"T"+restaurant.endtime+":31.587Z",
	"person": restaurant.seats,
	"table_id": [Tableid]
};

if(Tableid){

}else{
toast.info("PLEASE SELECT TABLE")
	return false;
}

axios.post(`https://admin.order2go.us:3001/v1/reservation/createUpdate`,
object,
{
headers:{
	  "Content-Type": "application/json",
	  Authorization: "Bearer " + localStorage.getItem("@access_token"),
	}
  })
  .then((response) => {
	
	  if(response.data.status){
		  toast.success("Successfully Booked");
		  history.push("/Reservation")
          return;
		}else{
		  toast.info(response.message)}
  })
  .catch((error) => {
	toast.info(error.message);
  });
//   setShowstatus(true);
}


const confirmed=()=>{
	if(showstatus){
		setdisplay("none")
	return(
		<div className="main">
		<div id="confirm">
			<div className="icon icon--order-success svg add_bottom_15">
				<svg xmlns="http://www.w3.org/2000/svg" width="72" height="72">
					<g fill="none" stroke="rgb(236, 52, 89)" stroke-width="2">
						<circle cx="36" cy="36" r="35" style={{strokeDasharray:"240px 240px", strokeDashoffset: "480px"}}></circle>
						<path d="M17.417,37.778l9.93,9.909l25.444-25.393" style={{strokeDasharray:"50px, 50px", strokeDashoffset: "0px",}}></path>
					</g>
				</svg>
			</div>
			<h3>Booking Confirmed!</h3>
		</div>
	</div>
	)
	}
}

	return (
		<div>
			<ToastContainer />
			<div >
				<main className='bg_gray pattern'>
					<div className=" container margin_60_40 margin_mobile">
						<div className="  row justify-content-center">
							<div className="  col-lg-7" >
								<div className="box_booking_2">
									<div className=" head">
										<div className="title">
											<h3 style={{ color: "white" }}>{restaurant.name}</h3>
											{restaurant.restaurant_address}
										</div>
									</div>
									<div className="main" style={{display:display}}>
										<ul>
											<li>Date<span>{restaurant.date}</span></li>
											<li>Hour<span>{restaurant.starttime+"  to "+restaurant.endtime}</span></li>
											<li>People<span>{restaurant.seats} Seats</span></li>
										</ul>

										<hr></hr>

										<h6>Select Table</h6>
										<div className="form-group">
			
											<select onChange={(e)=>{setTableid(e.target.value)}} className="form-control">
											<option value="">Select Option</option>
											
											{Table.map((data, index) => (<option value={`${data.id}`}>{data.name}</option>
											
											))}

										</select>


										</div>
										<a style={{color:"#fff"}} className="btn_1 full-width mb_5" onClick={()=>submit()}>Reserve Now</a>
									</div>


									{confirmed()}
								</div>

							</div>


						</div>

					</div>


				</main>
			</div>


		</div>
	)
}

export default Booking