import React, { useContext } from "react";
import { IconContext } from "react-icons/lib";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavMenu,
  NavItem,
  Link,
} from "./NavbarElements";
import { FiUser, FiLogIn } from "react-icons/fi";
import { AiOutlineShoppingCart } from "react-icons/ai";
import "./style.css";
import { UserContext } from "../../UserContext";


const Navbar = () => {

  const {Cartsize, user} = useContext(UserContext)
 return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={false}>
          <NavbarContainer>
            <NavLogo to="/">order2go</NavLogo>
            <NavMenu>
              <NavItem>
                <Link to="/">Home</Link>
              </NavItem>
              <NavItem>
                <Link to="#">
                  {/* <AiOutlineShoppingCart color="black" /> */}
                  {/* {Cartsize === 0 ? <div></div> : 
                  <span className="badge badge-warning" id="lblCartCount">
                      {Cartsize}
                    </span>} */}
                </Link>
              </NavItem>
              <NavItem>
                {user.length === 0 ? (
                  <Link to="/Login">
                    Login <FiLogIn  color="black" />
                  </Link>
                ) : (
                  <Link to="/Cardlist">
                    {user.name?.replaceAll('"', "").substring(0, 7)}{" "}
                    <FiUser style={{ fontSize: "22px" }} color="black"/>
                  </Link>
                )}
              </NavItem>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};
export default Navbar;
