import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { UserContext } from "./UserContext"
import 'react-toastify/dist/ReactToastify.css';


/////////////////
import Home from './pages'
import Log from './components/Auth/Log';
import Resturent from './components/ResturentPage/Resturent';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Register from './components/Auth/Reg';
import Otp from './components/Auth/Otp';
import Forget from './components/Auth/Forget';
import Updatepassword from './components/Auth/Update';
import Booking from './components/ResturentPage/Booking/booking';
import Payment from './components/ResturentPage/Booking/payment';
import Card from './components/Card';
import Review from './components/ResturentPage/review/Review';
import Cardlist from './components/UserSidebar/Index'
import Reservation from './components/UserSidebar/Pages/Reservation';
import Myorderlist from './components/UserSidebar/Pages/Myorderlist';
import favrestaurant from './components/UserSidebar/Pages/favrestaurant';
import SidebarReview from './components/UserSidebar/Pages/sidebarreview';
import Foodlist from './components/UserSidebar/Pages/foodlist';
import Cart from './components/UserSidebar/Pages/Cart';
import PaymentConfirm from './components/UserSidebar/Pages/PaymentConfirm';

function App() {
  const PRODUCTS =
    JSON.parse(localStorage.getItem("@cartItem")) == null
      ? []
      : JSON.parse(localStorage.getItem("@cartItem"));

  const USER =
    JSON.parse(localStorage.getItem("@user_data")) == null
      ? []
      : JSON.parse(localStorage.getItem("@user_data"));

  const CLONE_PRODUCTS = JSON.parse(JSON.stringify(PRODUCTS));
  const CLONE_USER = JSON.parse(JSON.stringify(USER));
  const [products, setProducts] = useState(CLONE_PRODUCTS);
  const [user, setUser] = useState(CLONE_USER);  
  const [Cartsize, setCartsize] = useState(products.length);

  useEffect(() => {
    // setCartsize(products.length);
    setProducts(JSON.parse(localStorage.getItem("@cartItem")) == null
      ? []
      : JSON.parse(localStorage.getItem("@cartItem")))

    setUser(JSON.parse(localStorage.getItem("@user_data")) == null
      ? []
      : JSON.parse(localStorage.getItem("@user_data")))
    sizeofdata()
    
  }, [])

  const sizeofdata = async () => {
    await setCartsize(products.length)
    await setUser(user)
  }


  return (
    <UserContext.Provider value={{ Cartsize, setCartsize, user, setUser }}>
      <Router>
        <Navbar />
        <Switch>
          <Route path="/Resturent/:id" component={Resturent} exact />
          <Route path="/" component={Home} exact />
          <Route path="/Cart" component={Card} exact />
          <Route path="/Review" component={Review} exact />
          <Route path="/Login" component={Log} exact />
          <Route path="/register" component={Register} exact />
          <Route path="/Forget" component={Forget} exact />
          <Route path="/updatepassword" component={Updatepassword} exact />
          <Route path="/otp" component={Otp} exact />
          <Route path="/Booking" component={Booking} exact />
          <Route path="/Payment" component={Payment} exact />
          <Route path="/Cardlist" component={Cardlist} exact />
          <Route path="/PaymentConfirm" component={PaymentConfirm} exact />
          <Route path="/Reservation" component={Reservation} exact />
          <Route path="/myorder" component={Myorderlist} exact />
          <Route path="/favrestaurant" component={favrestaurant} exact />
          <Route path="/sidebarreview" component={SidebarReview} exact />
          <Route path="/Foodlist" component={Foodlist} exact />
          <Route path="/Card" component={Cart} exact />
        </Switch>
        <Footer />
      </Router>
    </UserContext.Provider>
  );
}

export default App;