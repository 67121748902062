import React from 'react';
import InfoSection from '../components/ResturentPage';
import Banner from '../components/banner/Banner'

const Home = () => {


    return (
        <>
            <InfoSection />
            <br/>
            <Banner />
         
        </>
    );
};

export default Home;