import React, { useLayoutEffect, useState, useEffect } from "react";
import Calendar from "react-calendar";
import { toast, ToastContainer } from "react-toastify";
import Slider from "react-slick";
import axios from "axios";
import { AiFillStar } from "react-icons/ai";
import { MdFavoriteBorder, MdFavorite } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { AiOutlineFileAdd } from "react-icons/ai";
const Category = (props) => {
  const [favfood, setfavfood] = useState([]);
  const history = useHistory();
  const [restaurantreview, setRestaurantreview] = useState([]);
  useEffect(() => {
    alreadyfav();
    getfavdata();
  }, []);

  const handleClick1 = async (data) => {
    if (!localStorage.getItem("@access_token")) {
      if (window.confirm("You Want to add Favourite please login?")) {
        history.push("/login");
      } else {
        return;
      }
    }

    const menuinfood = favfood.includes(data.menu_id);

    const menu_id = data.menu_id;

    if (menuinfood == false) {
      axios
        .post(
          "https://api.order2go.us:3001/v1/fav_food/create",
          {
            menu_id: menu_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization:
                "Bearer " + (await localStorage.getItem("@access_token")),
            },
          }
        )
        .then((response) => {
          var stringified = JSON.stringify(response);
          console.log(stringified);
          toast.success(response.data.message);
          alreadyfav();
        })
        .catch((err) => {});
    } else {
      axios
        .post(
          "https://api.order2go.us:3001/v1/fav_food/delete",
          {
            menu_id: menu_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization:
                "Bearer " + (await localStorage.getItem("@access_token")),
            },
          }
        )
        .then((response) => {
          var stringified = JSON.stringify(response);
          var parsedObj = JSON.parse(stringified);
          console.log(stringified);
          if (parsedObj.status == 200) {
            console.log("Favo Removed , ", response.data);
            toast.info(response.data.message);
            alreadyfav();
          } else {
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  const getfavdata = async () => {
    axios
      .get(
        `https://api.order2go.us:3001/v1/review/listByRestaurant?restaurant_id=${props.Categorypropsdata.Restaurantid}`,
        {
          headers: {
            "Content-Type": "application/json",
            from_mobile: "true",
            restaurant_id: props.Categorypropsdata.Restaurantid,
            Authorization:
              "Bearer " + (await localStorage.getItem("@access_token")),
          },
        }
      )
      .then((response) => {
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);
        if (parsedObj.status == true) {
          setRestaurantreview(parsedObj.data);
        } else {
        }
      });
  };
  const alreadyfav = () => {
    axios
      .get("https://api.order2go.us:3001/v1/fav_food/list", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("@access_token"),
        },
      })
      .then((response) => {
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);
        const arr = [];
        if (parsedObj.status == true) {
          response.data.data.map((item, index) => {
            arr.push(item.menu_id);
          });
          console.log(JSON.stringify(arr));
          console.log("Favourite Items", JSON.stringify(arr));
          // toast.info("already add fav food")
          setfavfood(arr);
        } else {
          setfavfood([])
        }
      })
      .catch((error) => {});
  };
  const [stockData, setstockData] = useState([]);
  const [length, setLength] = useState([]);
  useLayoutEffect(() => {
    setInterval(() => {
      setLength(window.innerWidth);
    }, 1000);
  });
  const addDefaultSrc = (ev) => {
    ev.target.src =
      "https://images.unsplash.com/photo-1468777675496-5782faaea55b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGhlYWx0eSUyMGZvb2R8ZW58MHx8MHx8&auto=format&fit=crop&w=400&q=60";
  };
  useEffect(() => {
    async function fetchMyAPI() {
      let response = await fetch(
        `https://admin.order2go.us:3001/v1/menus/findAllByCategory`,
        {
          headers: {
            "Content-Type": "application/json",
            from_mobile: "true",
            restaurant_id: props.Categorypropsdata.Restaurantid,
          },
        }
      );
      response = await response.json();
      var stringified = JSON.stringify(response);
      var parsedObj = JSON.parse(stringified);
      if (parsedObj.status == true) {
        setstockData(parsedObj.data);
      } else {
      }
    }
    fetchMyAPI();
  }, []);

  const FoodView = () => {
    return (
      <div>
        {props.Categorypropsdata.status ? (
          props.Categorypropsdata.categorydetail.length === 0 &&
          JSON.stringify(props.Categorypropsdata.status) == "true" ? (
            <div style={{ padding: "7%", textAlign: "center", color: "grey" }}>
              <AiOutlineFileAdd style={{ fontSize: "25px" }} /> Empty Food list
            </div>
          ) : (
            <div>
              {props.Categorypropsdata.subCategory.map((data, index) => (
                <div className="containerfood">
                  <div className="foodcard">
                    <div className="imagefood">
                      <img
                        onError={addDefaultSrc}
                        src={`https://api.order2go.us:3001/${
                          data.picture === null
                            ? "uploads/images/Image-1637340817630.jpg"
                            : data.picture
                        }`}
                        className="lazy loaded"
                        alt=""
                        data-was-processed="true"
                        style={{
                          padding: "4px",
                          width: "120px",
                          height: "102px",
                          marginRight: "4px",
                        }}
                      />
                    </div>
                    <div className="foodname"> {data.name}</div>
                    <div className="fooddetails">
                      <p className="fooddesc">{data.desc}</p>
                    </div>
                    <div
                      className="price"
                      style={{ textAlign: "center", cursor: "pointer" }}
                    >
                      $ {data.menu_price.price}
                      <br></br>
                      {favfood.includes(data.menu_price.menu_id) ? (
                        <MdFavorite
                          style={{
                            fontSize: "18px",
                            color: "crimson",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleClick1(data.menu_price);
                          }}
                        />
                      ) : (
                        <MdFavoriteBorder
                          style={{
                            fontSize: "18px",
                            color: "crimson",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleClick1(data.menu_price);
                          }}
                        />
                      )}
                    </div>
                    <div className="addcart" style={{ textAlign: "center" }}>
                      <a
                        style={{
                          borderRadius: "12px",
                          cursor: "pointer",
                          fontSize: "13px",
                          textDecoration: "none",
                        }}
                        className="button-34"
                        onClick={() =>
                          props.Categorypropsdata.CheckAlreadyCart(data)
                        }
                      >
                        Add Cart
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
        ) : (
          stockData.map((data, key) => (
            <div className="containerfood">
              <div className="foodcard">
                <div className="imagefood">
                  <img
                    src={`https://api.order2go.us:3001/${
                      data.picture === null
                        ? "uploads/images/Image-1637340817630.jpg"
                        : data.picture
                    }`}
                    className="lazy loaded"
                    alt=""
                    data-was-processed="true"
                    onError={addDefaultSrc}
                    style={{
                      padding: "4px",
                      width: "120px",
                      height: "102px",
                      marginRight: "4px",
                    }}
                  />
                </div>
                <div className="foodname"> {data.name}</div>
                <div className="fooddetails">
                  <p className="fooddesc">{data.desc}</p>
                </div>
                <div
                  className="price"
                  style={{ textAlign: "center", cursor: "pointer" }}
                >
                  $ {data.menu_price.price} <br></br>
                  {favfood.includes(data.menu_price.menu_id) ? (
                    <MdFavorite
                      style={{
                        fontSize: "18px",
                        color: "crimson",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        handleClick1(data.menu_price);
                      }}
                    />
                  ) : (
                    <MdFavoriteBorder
                      style={{
                        fontSize: "18px",
                        color: "crimson",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        handleClick1(data.menu_price);
                      }}
                    />
                  )}
                </div>
                <div className="addcart" style={{ textAlign: "center" }}>
                  <a
                    style={{
                      borderRadius: "12px",
                      cursor: "pointer",
                      fontSize: "13px",
                      textDecoration: "none",
                    }}
                    className="button-34"
                    onClick={() =>
                      props.Categorypropsdata.CheckAlreadyCart(data)
                    }
                  >
                    Add Cart
                  </a>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    );
  };

  const Review = () => {
    return (
      <div>
        <div id="pane-B" role="tabpanel" aria-labelledby="tab-B">
          <div
            id="collapse-B"
            className="collapse show"
            role="tabpanel"
            aria-labelledby="heading-B"
          >
            <hr />
            <div className="card-body reviews">
              {restaurantreview.length > 0 ? (
                <div id="reviews">
                  {restaurantreview.map((item, index) => (
                    <div className="review_card">
                      <h4>{item.comment}</h4>
                      <div style={{ marginTop: "-8%" }} className="row">
                        <div className="col-md-2 user_info">
                          {item.name}{" "}
                          <img
                            style={{
                              height: "90px",
                              width: "90px",
                              borderRadius: "10%",
                              marginBottom: "-4%",
                            }}
                            src={item.order.picture}
                            alt=""
                          />
                        </div>
                        <div className="col-md-10 review_content">
                          <div className="clearfix add_bottom_15">
                            <span className="rating">
                              <AiFillStar
                                style={{
                                  color: "orange",
                                  fontSize: "23px",
                                  marginBottom: "10px",
                                }}
                              />
                              {item.rating}/5<strong>Rating average</strong>
                            </span>
                          </div>
                          <p>{item.comment}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  style={{ padding: "7%", textAlign: "center", color: "grey" }}
                >
                  <AiOutlineFileAdd style={{ fontSize: "25px" }} /> Empty Review
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const SubCategory_list = () => {
    return (
      <div>
        {props.Categorypropsdata.status ? (
          <div
            style={{
              fontSize: "22px",
              fontWeight: "bolder",
              textAlign: "center",
              borderRight: "2px solid gray",
            }}
          >
            <div
              style={{
                border: "2px solid gray",
                borderRadius: "8px",
                cursor: "pointer",
                color: "#ed2359",
                fontSize: "12px",
                width: "60px",
                marginLeft: "40px",
              }}
              onClick={() => props.Categorypropsdata.setstatus(false)}
            >
              View All
            </div>
            <div>{props.Categorypropsdata.categoryname}</div>
          </div>
        ) : (
          <div
            style={{
              fontSize: "22px",
              fontWeight: "bolder",
              textAlign: "center",
              borderRight: "2px solid gray",
            }}
          >
            All Category
          </div>
        )}
        {props.Categorypropsdata.status
          ? props.Categorypropsdata.categorydetail.map((data, index) => (
              <div
                className="divcategory"
                style={{ textAlign: "right", padding: "3px 10px" }}
                onClick={() => {
                  props.Categorypropsdata.subcategory_list1(data);
                }}
              >
                {data.name}
              </div>
            ))
          : stockData.map((data, key) => (
              <div
                className="divcategory"
                style={{ textAlign: "right", padding: "3px 10px" }}
                onClick={() => {
                  props.Categorypropsdata.subcategory_list1(data.sub_category);
                  props.Categorypropsdata.Categorylist12({
                    id: data.sub_category.category_id,
                  });
                }}
              >
                {data.sub_category.name}
              </div>
            ))}
      </div>
    );
  };

  const Category = () => {
    return (
      <div style={{ display: props.Categorypropsdata.cateReview }}>
        <p style={{ fontSize: "19px", paddingLeft: "12px" }}>Menu & Photos</p>
        <div
          className="pictures magnific-gallery clearfix"
          style={{
            padding: `0 ${
              props.Categorypropsdata.Category.length > 6
                ? "1.5%"
                : length < 500
                ? "7%"
                : "23%"
            }`,
          }}
        >
          <Slider {...props.Categorypropsdata.settings}>
            {props.Categorypropsdata.Category.map((data, index) => (
              <div>
                <figure
                  onClick={() => props.Categorypropsdata.Categorylist1(data)}
                >
                  <a
                    title="Photo title"
                    data-effect="mfp-zoom-in"
                    onClick={() => props.Categorypropsdata.Categorylist1(data)}
                  >
                    <img
                      src={`https://api.order2go.us:3001/${
                        data.picture === null
                          ? "uploads/images/Image-1637340817630.jpg"
                          : data.picture
                      }`}
                      className="lazy loaded"
                      alt=""
                      data-was-processed="true"
                      onError={addDefaultSrc}
                      style={{
                        cursor: "pointer",
                        objectFit: "cover",
                        objectPosition: "center",
                        padding: "4px",
                        width: "100%",
                        height: "10vw",
                      }}
                    />
                  </a>
                </figure>
                <p>{data.name}</p>
              </div>
            ))}
          </Slider>
        </div>
        <div>
          <div className="container-gridit">
            {SubCategory_list()}
            {FoodView()}
          </div>
        </div>
      </div>
    );
  };

  const Calender = () => {
    return (
      <div
        style={{ display: props.Categorypropsdata.calReview }}
        className="col"
      >
        <div className="box_booking">
          <div className="head">
            <h3>Book your table</h3>
          </div>
          <div className="main">
            <input type="text" id="datepicker_field" />
            <div id="DatePicker" className="hasDatepicker">
              <div
                className="ui-datepicker-inline ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all"
                style={{ display: "block" }}
              >
                <Calendar
                  onChange={props.Categorypropsdata.onChange}
                  value={props.Categorypropsdata.date}
                />
              </div>
            </div>
            <div className="dropdown">
              <a data-toggle="dropdown" style={{ textAlign: "center" }}>
                {props.Categorypropsdata.current}
                <label style={{ marginLeft: "6%" }}>Start:</label>
                <input
                  type="time"
                  onChange={(e) => {
                    props.Categorypropsdata.setTime(e.target.value);
                  }}
                  style={{ marginLeft: "30px", border: "none" }}
                />
                <label style={{ marginLeft: "6%" }}>End:</label>
                <input
                  type="time"
                  onChange={(e) => {
                    props.Categorypropsdata.setTime1(e.target.value);
                  }}
                  style={{ marginLeft: "30px", border: "none" }}
                />
              </a>
            </div>
            <div className="dropdown people">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div
                  data-toggle="dropdown"
                  style={{
                    itemAlign: "center",
                    paddingTop: "15px",
                  }}
                >
                  People{" "}
                  <span id="selected_people " className="text-danger">
                    {props.Categorypropsdata.peoplecount}
                  </span>
                </div>
                <a
                  data-toggle="dropdown"
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <span
                    style={{
                      border: "1px solid gray",
                      borderRadius: "4px",
                      padding: "4px 12px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      props.Categorypropsdata.setPeoplecount(
                        props.Categorypropsdata.peoplecount + 1
                      )
                    }
                  >
                    +
                  </span>
                  <span
                    style={{
                      border: "1px solid gray",
                      borderRadius: "4px",
                      padding: "4px 12px",
                      marginLeft: "2px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      props.Categorypropsdata.setPeoplecount(
                        props.Categorypropsdata.peoplecount === 1
                          ? 1
                          : props.Categorypropsdata.peoplecount - 1
                      )
                    }
                  >
                    -
                  </span>
                </a>
              </div>
            </div>
            <a
              className="btn_1"
              style={{ color: "white", marginLeft: "40%" }}
              onClick={() => props.Categorypropsdata.Reserve()}
            >
              Reserve Now
            </a>
          </div>
        </div>
      </div>
    );
  };

  const Info = () => {
    return (
      <div
        className="para1"
        style={{ display: `${props.Categorypropsdata.info}` }}
      >
        <p
          style={{
            fontSize: "19px",
            fontWeight: "bolder",
            paddingLeft: "12px",
          }}
        >
          About {props.Categorypropsdata.restaurant.name}
        </p>
        <p
          style={{
            textAlign: "left",
            textIndent: "2cm",
          }}
        >
          A good restaurant is like a vacation; it transports you, and it
          becomes a lot more than just about the food. I went to a restaurant
          that serves 'breakfast at any time.
        </p>
      </div>
    );
  };

  return (
    <div className="col-lg-8">
      <div className="tabs_detail">
        <div className="tab-content" role="tablist">
          <div id="pane-A" role="tabpanel" aria-labelledby="tab-A">
            <div
              id="collapse-A"
              className="collapse show"
              role="tabpanel"
              aria-labelledby="heading-A"
            >
              <div className="info_content">
                {Info()}
                <hr />
                {Calender()}
                <div style={{ display: props.Categorypropsdata.Review }}>
                  {Review()}
                </div>
                {Category()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Category;
