import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const Cart = (props) => {
  console.log("Carts Data : ",props.Cartpropsdata)
  const [totalAmount, setTotalAmount] = useState(props.Cartpropsdata.subTotal);
  const [discount, setdiscount] = useState( (props.Cartpropsdata.subTotal * props.Cartpropsdata.products[0]?.discount) /100);
  const [tax, settax] = useState(
    (props.Cartpropsdata.subTotal * props.Cartpropsdata.products[0]?.tax) / 100
  );
  const [condition, setcondition] = useState(false);

  useEffect(() => {
    setTotalAmount(props.Cartpropsdata.subTotal);
    setdiscount(
      (props.Cartpropsdata.subTotal * props.Cartpropsdata.products[0]?.discount) /100 );
    settax( (props.Cartpropsdata.subTotal * props.Cartpropsdata.products[0]?.tax) / 100 );

    if (
      props.Cartpropsdata.subTotal >= props.Cartpropsdata.products[0]?.min_order_amount
    ) {
      setcondition(true);
    }
  }, []);

  const setoffer = () =>{
    setTotalAmount(props.Cartpropsdata.subTotal);
    setdiscount( (props.Cartpropsdata.subTotal * props.Cartpropsdata.products[0]?.discount) /100 );
    settax( (props.Cartpropsdata.subTotal * props.Cartpropsdata.products[0]?.tax) / 100 );

    if (
      props.Cartpropsdata.subTotal >=
      props.Cartpropsdata.products[0]?.min_order_amount
    ) {
      setcondition(true);
    }
  }

  // const setLocalItemm = () => {
  //   // const value = event.target.value;
  //   // const valueInt = parseInt (value);
  //   const cloneProducts = [props.Cartpropsdata];
    
  //   cloneProducts[0].discount = props.Cartpropsdata.subTotal >= props.Cartpropsdata.products[0]?.min_order_amount ? (props.Cartpropsdata.subTotal * props.Cartpropsdata.products[0]?.discount) /100 :  0;
  //   cloneProducts[0].tax = props.Cartpropsdata.subTotal >= props.Cartpropsdata.products[0]?.min_order_amount ?  (props.Cartpropsdata.subTotal * props.Cartpropsdata.products[0]?.tax) / 100 :  0
  //   cloneProducts[0].total = props.Cartpropsdata.subTotal >= props.Cartpropsdata.products[0]?.min_order_amount  
  //   ? props.Cartpropsdata.formatCurrency(
  //       props.Cartpropsdata.subTotal -
  //         (props.Cartpropsdata.subTotal *
  //           props.Cartpropsdata.products[0].discount) /
  //           100 +
  //         (props.Cartpropsdata.subTotal *
  //           props.Cartpropsdata.products[0].tax) /
  //           100
  //     )
  //   : props.Cartpropsdata.formatCurrency(
  //       props.Cartpropsdata.subTotal
  //     );
    
  //   // setProducts (cloneProducts);

  //   localStorage.setItem ('@cartItem', JSON.stringify (cloneProducts));
  // };

  const histroy = useHistory();

  const clearcart = () => {
    if (window.confirm("Are you sure you want to Clear?")) {
      localStorage.removeItem("@cartItem");
      props.Cartpropsdata.setProducts([]);
      toast.info("Clear Successfully");
    } else {
      console.log("Clear error");
    }
  };

  return (
    <div
      className="col-lg-4"
      id="sidebar_fixed"
      style={{
        position: "relative",
        overflow: "visible",
        boxSizing: "border-box",
        marginTop: "4%",
        minHeight: "1px",
      }}
    >
      <div
        className="theiaStickySidebar"
        style={{
          paddingTop: "0px",
          paddingBottom: "1px",
          position: "static",
          transform: "none",
          top: "0px",
        }}
      >
        <div className="box_booking" style={{ position: "sticky" }}>
          <div
            className="head"
            style={{ textAlign: "center", position: "sticky" }}
          >
            <h3 style={{ textAlign: "center" }}>Cart List</h3>
          </div>

          <div>
            <div>
              {props.Cartpropsdata.products.map((product, index) => {
               
                return (
                  <div className="_1t-Al _1XUXj">
                    
                    <div className="_3YMqW"></div>
                    <div className="_2ObNr _2Y5ZT _2qOpI">
                      <div>
                        <div className="_2zsON"></div>
                        <div className="MGAj1">
                          <div className="_2bXOy">
                            <div className="_3SG03">
                              <div className="_33KRy">
                                <img
                                  src={`https://api.order2go.us:3001/${
                                    product.picture == null
                                      ? "uploads/images/Image-1637340817630.jpg"
                                      : product.picture
                                  }`}
                                  width="40px"
                                  alt=""
                                />{" "}
                                {product.name}
                              </div>
                            </div>
                            <div className="_2bWmk">
                              <div className="_1yTZI">
                                <div className="_29ugw _3L1X9">
                                  <div className="_1RPOp _36fT9 _4aKW6">
                                    ADD
                                  </div>
                                  <div className="_3Hy2E hDN3x _4aKW6">-</div>

                                  <div
                                    className="_1ds9T"
                                    onClick={(event) =>
                                      props.Cartpropsdata.onChangeProductQuantity(
                                        index,
                                        event,
                                        "+"
                                      )
                                    }
                                  >
                                    +
                                  </div>
                                  <div
                                    className="_29Y5Z"
                                    onClick={(event) =>
                                      props.Cartpropsdata.onChangeProductQuantity(
                                        index,
                                        event,
                                        "-"
                                      )
                                    }
                                  ></div>

                                  <div className="_2zAXs">{product.qty}</div>
                                </div>
                                <div
                                  className="_1mx0r"
                                  style={{ marginRight: "20px" }}
                                >
                                  <span className="_2W2U4">
                                    $ {product.price}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="_3DPdG"></div>
                      </div>
                    </div>
                    <div className="_1v28S"></div>
                  </div>
                );
              })}

              {props.Cartpropsdata.products.length === 0 ? (
                <div style={{ margin: "10% 0%" }}>
                  <h6 style={{ textAlign: "center" }}>Cart is Empty</h6>
                </div>
              ) : (
                <div className="EEeV3">
                  <div className="_161V3">
                    <div className="_1DWmI" style={{ marginLeft: "10px" }}>
                      Subtotal
                    </div>
                    <div style={{ marginRight: "20px" }}>
                      <span>$ {props.Cartpropsdata.total}</span>
                    </div>
                  </div>

                  <div className="_26NCu" style={{ marginLeft: "10px" }}>
                    Extra charges may apply
                  </div>

                  <div className="_161V3">
                    <div
                      className="_1DWmI"
                      style={{ marginLeft: "10px", fontSize: "12px" }}
                    >
                      Discount
                    </div>
                    <div style={{ marginRight: "20px", fontSize: "12px" }}>
                    {/* {setoffer()} */}
                      {/* <span>{condition ? "$" + discount : "$" + 0}</span> */}
                      <span>
                        { props.Cartpropsdata.subTotal >= props.Cartpropsdata.products[0]?.min_order_amount ? "$" + (props.Cartpropsdata.subTotal * props.Cartpropsdata.products[0]?.discount) /100 : "$" + 0}</span>
                    </div>
                  </div>

                  <div className="_161V3">
                    <div
                      className="_1DWmI"
                      style={{ marginLeft: "10px", fontSize: "12px" }}
                      >
                      Tax
                    </div>
                    <div style={{ marginRight: "20px", fontSize: "12px" }}>
                     <span> { props.Cartpropsdata.subTotal >= props.Cartpropsdata.products[0]?.min_order_amount ? "$" + (props.Cartpropsdata.subTotal * props.Cartpropsdata.products[0]?.tax) / 100 : "$" + 0}</span>
                      {/* <span> {condition ? "$" + tax : "$" + 0}</span> */}
                    </div>
                  </div>

                  <div className="_161V3" style={{ marginTop: "5%" }}>
                    <div
                      className="_1DWmI"
                      style={{ marginLeft: "10px", fontSize: "20px" }}
                    >
                      Total
                    </div>

                    <div style={{ marginRight: "20px", fontSize: "20px" }}>
                      <span>
                        {props.Cartpropsdata.subTotal >= props.Cartpropsdata.products[0]?.min_order_amount  
                          ? props.Cartpropsdata.formatCurrency(
                              props.Cartpropsdata.subTotal -
                                (props.Cartpropsdata.subTotal *
                                  props.Cartpropsdata.products[0].discount) /
                                  100 +
                                (props.Cartpropsdata.subTotal *
                                  props.Cartpropsdata.products[0].tax) /
                                  100
                            )
                          : props.Cartpropsdata.formatCurrency(
                              props.Cartpropsdata.subTotal
                            )}
                      </span>
                    </div>
                  </div>

                  <button
                    className="_1DWmI"
                    style={{
                      marginLeft: "85%",
                      fontSize: "12px",
                      backgroundColor: "#ed2359",
                      color: "white",
                      borderRadius: "3px",
                      padding: "3px",
                    }}
                    onClick={() => clearcart()}
                  >
                    clearAll
                  </button>

                  <h1>{props.Cartpropsdata.user}</h1>
                  {props.Cartpropsdata.user ? (
                    <button
                      className="_1gPB7"
                      onClick={() => {
                        // setLocalItemm()
                        histroy.push("/PaymentConfirm");
                      }}
                    >
                      Checkout →
                    </button>
                  ) : (
                    <button
                      className="_1gPB7"
                      onClick={() => {
                        if (window.confirm("please login before checkout")) {
                          histroy.push("/login");
                        } else {
                          return;
                        }
                      }}
                    >
                      Checkout →{" "}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
