import React, { useState, useEffect } from "react";
import "./payment.css";
import { useHistory } from "react-router-dom";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { toast, ToastContainer } from "react-toastify";


const Payment = (props) => {
  const [cardData, setCardData] = useState([]);
  const [cardDetails, setCardDetails] = useState([]);

  const history = useHistory();

  const [number, SetNumber] =  useState("");
  const [name, SetName] =  useState("");
  const [month, SetMonth] = useState(""); 
  const [expiry, SetExpiry] = useState("");
  const [focus, SetFocus] = useState("");
  const [issuer, Setissuer] = useState("");



  useEffect(()=>{
    OldData(); 
  },[])
  const handleDate = (e) => {
    SetMonth(e.target.value);
    // SetExpiry(e.target.value);
    };
  const handleExpiry = (e) => {
    SetExpiry(month.concat(e.target.value));
  };



  const   handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      Setissuer({ issuer });
    }
  };

 const OldData = () => {
    const values = localStorage.getItem("@card_details");
    if (values === null) {
      console.log("Card is empty");
    } else {
      var data = [];
      data.push(values);
      const mydata = JSON.parse(data);
      setCardData(mydata);
      const idToRemove = cardDetails.number;
      console.log("Card Length", mydata.length);
      if (mydata.length === 0) {
      } else {
        mydata.map((item, index) => {
          if (item.id === idToRemove) {
            console.log("CARD ALREADY ADDED");
          }
        });
      }
    }
  };

  const getCardData = async () => {
    let transactions = await localStorage.getItem("@card_details");
    if (transactions) {
      return JSON.parse(transactions);
    } else {
      return [];
    }
  };

  const storeCardData = async () => {
    let existingTransactions = await getCardData();
   const updatedTransactions = [
      ...existingTransactions,
      {
        Name: name,
        number: number,
        Month: month,
        Year: expiry,
  
      },
    ];
    await localStorage.setItem(
      "@card_details",
      JSON.stringify(updatedTransactions)
    );

    OldData();
    toast.success("CARD ADDED SUCCESSSFULLY");
    history.push("/Card");
    
  };


const CheckAlreadyCardStored = async () => {
    const idToRemove = number;
    
    if (idToRemove !== null) {

      if (cardData.length === 0) {
        storeCardData();
      } else {
        var item = await cardData.find((item) => item.number === idToRemove);
        var i = 0;
        if (await item) {
          toast.info("CARD Already Added");
          history.push("/Card");
          return;
  
        } else {
          storeCardData();
          toast.success("ITEM ADDED TO CART Successfully");
        }
      }
    } else {
      toast.error("PLEASE FILL THE INPUT");
    }
  };

 
  return (
    <div>

<form style={{borderRadius:"10px",boxShadow: "0 0 50px #d9d9d9",padding:"20px",height:"auto",marginBottom:"50px"}}>
   <div   clasName="rccs__card rccs__card--unknown">
 <Cards
          number={number}
          name={name}
          expiry={expiry}
          focused={expiry}
          callback={handleCallback}
        />
      </div>

      <br />
    <div className="row">
          <div className="col-sm-11">
            <label for="name">Card Number</label>
            <input
              type="number"
              className="form-control"
              value={number}
              name="number"
              maxlength={16}
              pattern={'[0-9]+'}
              onChange={(e) => {
                if(e.target.value.length<17){

                  SetNumber(e.target.value);
                }
              }}
              onFocus={(e) => SetFocus(e.target.name)}
            ></input>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-sm-11">
            <label for="name">Name</label>
            <input
             pattern="[A-Za-z]"
              type="text"
              maxlength="16"
              className="form-control"
              value={name}
              name="name"
              onChange={(e) => {
                if(e.target.value.length<17){

                  SetName(e.target.value);
                }
              }}
              onFocus={(e) => SetFocus(e.target.name)}
            ></input>
          </div>
        </div>
        <br />
        <div className="row">
          <div
            className="col=sm-8"
            style={{
              ...{ "padding-right": "12em" },
              ...{ "padding-left": "1em" }
            }}
          >
            <label for="month">Expiration Date</label>
          </div>
      
        </div>

        <div className="row">
          <div className="col-sm-4">
            <select id="slectoption"
              className="form-control"
              name="expiry"
              
              style={{textAlign:"center"}}
              onChange={handleDate}
            >
              <option value=" ">month</option>
              <option value="01">Jan</option>
              <option value="02">Feb</option>
              <option value="03">Mar</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">Aug</option>
              <option value="09">Sep</option>
              <option value="10">Oct</option>
              <option value="11">Nov</option>
              <option value="12">Dec</option>
            </select>
          </div>
          &nbsp;
          <div className="col-sm-4">
            <select
              className="form-control"
              id="slectoption"
              name="expiry"   
              style={{textAlign:"center"}}
              onChange={handleExpiry}
              > 
              <option value=" ">Year</option>
              <option value="21">2021</option>
              <option value="22">2022</option>
              <option value="23">2023</option>
              <option value="24">2024</option>
              <option value="25">2025</option>
              <option value="26">2026</option>
              <option value="27">2027</option>
              <option value="28">2028</option>
              <option value="29">2029</option>
              <option value="30">2030</option>
              <option value="31">2031</option>
              <option value="32">2032</option>
              <option value="33">2033</option>
              <option value="34">2034</option>
              <option value="35">2035</option>
              <option value="36">2036</option>
              <option value="37">2037</option>
              <option value="38">2038</option>
              <option value="39">2039</option>
              <option value="40">2040</option>
              <option value="41">2041</option>
              <option value="42">2042</option>
              <option value="43">2043</option>
              <option value="44">2044</option>
              <option value="45">2045</option>
              <option value="46">2046</option>
              <option value="47">2047</option>
              <option value="48">2048</option>
              <option value="49">2049</option>
              <option value="50">2050</option>
            </select>
          </div>
        </div>
        <br />
        <input
        style={{backgroundColor:"#ed2359",color:"white"}}

        onClick={()=>CheckAlreadyCardStored()}
          className="btn btn-secondary form-control"
          value="Save"
        />
 

 </form>


    </div>
  );
};

export default Payment;
