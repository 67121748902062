import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import "./style/style.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { UserContext } from "../../../UserContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

var userdata =
  JSON.parse(localStorage.getItem("@user_data")) == null
    ? []
    : JSON.parse(localStorage.getItem("@user_data"));

const Update = () => {
  const [username, setUsername] = useState([]);
  const [statedata, setstatedata] = useState([]);
  const [citydata, setcitydata] = useState([]);
  const [profile, setProfile] = useState("");
  const [editIs, setEditIs] = useState(false);
  const [statevalue, SetStatevalue] = useState([]);
  const [cityvalue, Setcityvalue] = useState([]);
  const [zip, setZip] = useState("");
  const [city, setcity] = useState([]);
  const [address, setAddress] = useState([]);
  const [stateid, setStateid] = useState([]);
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    // updateUserData()
    // alert(user.city.id)
    setUsername(user.name);
    setProfile(user.picture);
    setAddress(user.address);
    setZip(user.zip);
    setStateid(user.state_id);
    if (user.city_id) {
      setcity(user.city_id);
    } else if (user.city.id) {
      setcity(user.city.id);
    }
    getaddress();
  }, []);

  const getaddress = () => {
    axios
      .get("https://api.order2go.us:3001/v1/location/getStates", {
        headers: {
          "Content-Type": "application/json",
          from_mobile: true,
        },
      })
      .then((response) => {
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);
        if (parsedObj.status == true) {
          SetStatevalue(response.data.data);
          const stateid = userdata.state_id ? userdata.state_id : 3;
          const statedata = response.data.data.find(
            (item) => item.id === stateid
          ); //stateid
          setstatedata(statedata);
          console.log("statedata : ", statedata);
        } else if (parsedObj.status === false) {
          console.log(JSON.stringify(response.data.data));
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
    const stateid = userdata.state_id ? userdata.state_id : 3;

    axios
      .get(
        `https://api.order2go.us:3001/v1/location/getCities?state_id=${stateid}`,
        {
          headers: {
            "Content-Type": "application/json",
            from_mobile: true,
          },
        }
      )
      .then((response) => {
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);
        if (parsedObj.status === true) {
          Setcityvalue(response.data.data);
          var cityid = 3;
          if (userdata.city_id) {
            cityid = userdata.city_id ? userdata.city_id : 3;
          } else if (userdata.city.id) {
            cityid = userdata.city.id ? userdata.city.id : 3;
          }
          const citydata = response.data.data.find(
            (item) => item.id === cityid
          ); //stateid
          setcitydata(citydata);
          console.log(citydata);
          // setcitydata(userdata.city_id)
        } else if (parsedObj.status === false) {
          console.log(JSON.stringify(response.data.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateUserData = async () => {
    axios
      .get("https://api.order2go.us:3001/v1/users/getUserData", {
        headers: {
          Authorization:
            "Bearer " + (await localStorage.getItem("@access_token")),
        },
      })

      .then((response) => {
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);
        if (parsedObj.status === true) {
          setUsername(response.data.data.name);
          // setProfile(response.data.data.picture);
          setZip(response.data.data.zip);
          setStateid(response.data.data.state_id);
          setcity(response.data.data.city.id);
          setAddress(response.data.data.address);

          console.log(JSON.parse(localStorage.getItem("@user_data")));
          var mydata = JSON.parse(localStorage.getItem("@user_data"));

          mydata.name = response.data.data.name;
          mydata.address = response.data.data.address;
          mydata.state_id = response.data.data.state_id;
          mydata.city_id = response.data.data.city.id;
          mydata.zip = response.data.data.zip;
          mydata.picture = response.data.data.picture;
          localStorage.setItem("@user_data", JSON.stringify(mydata));
          // localStorage.setItem( "@user_data",JSON.stringify(response.data.data));
          setInterval(function () {
            window.location.reload(false);
          }, 100);
        } else if (parsedObj.status == false) {
          console.log(JSON.stringify(response.data.data));
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const ChangeState = (e) => {
    setStateid(e);
    axios
      .get(`https://api.order2go.us:3001/v1/location/getCities?state_id=${e}`, {
        headers: {
          "Content-Type": "application/json",
          from_mobile: true,
        },
      })
      .then((response) => {
        var stringified = JSON.stringify(response.data);
        var parsedObj = JSON.parse(stringified);
        if (parsedObj.status === true) {
          Setcityvalue(response.data.data);
        } else if (parsedObj.status === false) {
          // console.log(JSON.stringify(response.data.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formsub = async (req, res) => {
    const name = {
      name: username,
      state_id: stateid,
      city_id: city,
      address: address,
      zip: zip,
    };

    // alert(JSON.stringify(name))
    // return;
    axios
      .post(
        "https://admin.order2go.us:3001/v1/users/updateProfile",
        {
          data: JSON.stringify(name),
        },
        {
          headers: {
            Authorization:
              "Bearer " + (await localStorage.getItem("@access_token")),
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          toast.success("successfully Updated");
          updateUserData();
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <div class="container margin_60_40 margin_mobile">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="box_booking_2" style={{ height: "1000px" }}>
            <div class=" head">
              <h5 style={{ marginRight: "90%", color: "white" }}>Update</h5>
            </div>

            <FilePond
              className="filepond--label-action"
              labelIdle="update your profile"
              files={`https://api.order2go.us:3001/uploads/images/Image-${profile?.split("-")[1]
                }`}
            />

            <div
              style={{
                position: "relative",
                left: "20px",
                width: "100px",
                height: "100px",
                borderRadius: "50%",
              }}
            >
              <input
                style={{ padding: "10%" }}
                type="file"
                onChange={(event) => {
                  const name = {
                    name: username,
                    state_id: stateid,
                    city_id: city,
                    address: address,
                    zip: zip,
                  };
                  const file = event.target.files[0];
                  console.log(file);
                  var formData = new FormData();
                  formData.append("Image", file);
                  formData.append("data", JSON.stringify(name));
                  axios
                    .post(
                      "https://admin.order2go.us:3001/v1/users/updateProfile",
                      formData,
                      {
                        headers: {
                          Authorization:
                            "Bearer " + localStorage.getItem("@access_token"),
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    )
                    .then((res) => {
                      toast.info(res.data.message);
                      updateUserData();
                    });
                }}
              />
            </div>

            <div
              style={{
                position: "relative",
                height: "450px",
                marginTop: "-60px",
              }}
              class="main"
            >
              <div class="form-group">
                <label htmlFor="">User Name</label>
                <input
                  class="form-control"
                  editable={editIs}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Name"
                />
                <i class="icon_pencil"></i>
              </div>

              <div class="form-group">
                <label htmlFor="">Address</label>
                <textarea
                  class="form-control"
                  editable={editIs}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  cols="10"
                  rows="2"
                >
                  {/* {userdata.address} */}
                </textarea>
              </div>

              <div class="form-group add_bottom_15">
                <label htmlFor="">Select State</label>
                <select
                  onChange={(e) => {
                    ChangeState(e.target.value);
                  }}
                  className="form-control"
                >
                  <option value={statedata.state_id}>{statedata.name}</option>
                  {statevalue &&
                    statevalue.map((item) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>
              </div>

              <div class="form-group add_bottom_15">
                <label htmlFor="">Select City</label>
                <select
                  onChange={(e) => {
                    setcity(cityvalue[e.target.value].id);
                    if (cityvalue[e.target.value].zip) {
                      setZip(cityvalue[e.target.value].zip);
                    }
                    console.log(cityvalue[e.target.value].id);
                    console.log(cityvalue[e.target.value].zip);
                  }}
                  className="form-control"
                >
                  <option value={citydata.id}>{citydata.name}</option>
                  {cityvalue &&
                    cityvalue.map((item, index) => (
                      <option value={index}>{item.name}</option>
                    ))}
                </select>
              </div>

              <div class="form-group add_bottom_15">
                <label htmlFor="">Zip Code</label>
                <input
                  class="form-control"
                  onChange={(e) => {
                    // if (e.target.value.length < 6) {
                    //   setZip(e.target.value);
                    // }
                    setZip(e.target.value);
                  }}
                  editable={editIs}
                  value={zip}
                  placeholder="Zipcode"
                  maxlength="6"
                />
                <i class="icon_phone"></i>
              </div>

              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <a
                  style={{ width: "45%", color: "white", cursor: "pointer" }}
                  class="btn_1 full-width mb_5"
                  onClick={() => formsub()}
                >
                  Update
                </a>
                <a
                  style={{ width: "45%" }}
                  class="btn_1 full-width outline mb_5"
                  onClick={() => "/"}
                >
                  cancel
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Update;
